import React, { useState, useEffect } from 'react';
import {
  List, ListItem, ListItemAvatar, Avatar, ListItemText, Typography
} from '@mui/material';

export default function CustomizedInputBase(props) {

  return (
    <List>
    <ListItem>
      <ListItemAvatar>
        {props.Message.Avatar && props.Message.Avatar !== "" ? (
          <Avatar alt={props.Message.SenderName} src={props.Message.Avatar} />
        ) : (
            <Avatar alt={props.Message.SenderName}></Avatar>
          )}

      </ListItemAvatar>
      <ListItemText
        primary={`${props.Message.SenderName} - ${props.Message.SenderCompany}`}
        secondary={
          <>
            <Typography
              sx={{ display: 'inline' }}
              component="span"
              variant="body2"
              color="primary"
            >
              {new Date(props.Message.TimeStamp).toLocaleString()}
            </Typography>
            <Typography>
              {props.Message.Body}
            </Typography>

          </>
        }
      />
      </ListItem>
      </List>
  );
}
