import React, { useState, useEffect } from "react";
import {
    Box,
    Stack,
    FormControl, InputLabel, Select, MenuItem, Button,
    TextField,
    InputAdornment,
    SvgIcon,
} from '@mui/material';
//import { useSelector } from 'react-redux';
//import { Search as SearchIcon } from 'react-feather';
//import AutoComplateBox from '../components/AutoComplete';
//import { NewPageId } from '../utils/CommonGraphql';
//import DateRange from '../components/DateRange';
import { adjustedTime, TimeStampEndofDay } from '../utils/DateTime';
import { DocumentType } from '../utils/Documents';
import DatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
//import GroupsIcon from '@mui/icons-material/Groups';
//import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
//import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
//import AlarmOnIcon from '@mui/icons-material/AlarmOn';

let DateNow = new Date();
var EndDate = TimeStampEndofDay(DateNow, "End");
let StartDate = adjustedTime(-30, "Start");

const Toolbar = (props) => {

    const initialState = {
        StartDate: StartDate,
        EndDate: EndDate,
        DocumentType: "Any"
    };

    const [state, setState] = useState(initialState);

    useEffect(() => {

        UpdateProps({ DocumentType: "Any" })

    }, []);

    const UpdateProps = (newvalue) => {

        let SortPref = {
            StartDate: state.StartDate,
            EndDate: state.EndDate,
            DocumentType: state.DocumentType,
            ...newvalue
        }

        console.log("DocumentPage.UpdateProps", SortPref)

        if (props.SortPref) {
            props.SortPref(SortPref);
        }
        //state.DocumentType, state.StartDate, state.EndDate
    }

    const SelectedValues = (name, item) => {

        let Newdate = "";
        //if start date > end date = change the end date
        if (name === "StartDate") {
            Newdate = TimeStampEndofDay(item, "Start");
        } else {
            Newdate = TimeStampEndofDay(item, "End");
        }

        setState((prevState) => ({
            ...prevState,
            [name]: Newdate,
        }))


        UpdateProps({ [name]: Newdate })
    }

    const SetValues = (data) => {

        setState(prevState => ({
            ...prevState,
            DocumentType: data,
        }));

        UpdateProps({ DocumentType: data })
    }

    const ProcessSearch = (e) => {
        if (props.SearchTerms) {
            props.SearchTerms(e);
        }
    }

    return (

        <Box mt={3}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Stack
                    direction={{ xs: 'column', lg: 'row' }}
                    spacing={2}
                >
                   
                    <DatePicker
                        value={state.StartDate}
                        label="Start Date"
                        onChange={item => SelectedValues("StartDate", item)}
                        renderInput={(props) => (
                            <TextField variant="outlined" {...props} />
                        )}
                    />

                    <DatePicker
                        label="End Date"
                        value={state.EndDate}
                        onChange={item => SelectedValues("EndDate", item)}
                        renderInput={(props) => (
                            <TextField variant="outlined" {...props} />
                        )}
                    />

                    <TextField
                        // fullWidth
                        disabled={props.disabled}
                        onChange={(e) => ProcessSearch(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <ManageSearchIcon />
                                </InputAdornment>
                            ),
                        }}
                        placeholder={`Search..`}
                        variant="outlined"
                    />
                    
                    <FormControl variant="outlined" style={{ width: '300px' }} >
                        <InputLabel id="demo-simple-select-outlined-label">
                            Document Type
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            //id={`select-${Item.key}-${index}`}
                            defaultValue="All"
                            error={state.ErrorMessage}
                            required={true}
                            disabled={state.disabled}
                            autoWidth={true}
                            value={state.DocumentType}
                            onChange={(e) => {
                                SetValues(e.target.value);
                            }}
                            label="Document Type"
                        >
                            <MenuItem key="isis02w3" value="Any">
                                Any
                            </MenuItem>
                            {DocumentType.map((o, indd) => (
                                <MenuItem key={indd + o.key} value={o.key}>
                                    {o.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl >

                </Stack>

            </LocalizationProvider>
        </Box>

    );
};

export default Toolbar;
