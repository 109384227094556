import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from './layouts/DashboardLayout';
import MainLayout from './layouts/MainLayout';
//import AccountView from './views/account/AccountView';
import ItemAddPage from './Add/ItemAddPage';
import CustomerListView from './views/customer/CustomerListView';
//import DashboardView from './views/reports/DashboardView';
import FrontPage from './components/FrontPage'
import ListItems from './List/ListItems';
//import CreateForm from './DynamicForm/FormCreatorClass';
import NotFoundView from './views/errors/NotFoundView';
import ProductListView from './views/product/ProductListView';
import Calendar from './Calendar/Calendar';
import RegisterView from './views/auth/RegisterView';
import SettingsView from './views/settings/SettingsView';
import StartPage from './views/auth/LoginView';
import ESCSetup from './Setup/ESCMinSetup';
import Setup from './Setup/Setup';
import CompanyPage from './Company/CompanyPage';
import SRPage from './List/SRPage';
import ServiceLandingPage from './NewService/ServiceLandingPage';
//import ShowMenuComponent from './components/ShowMenuComponent';
import ContractorSignup from './MainWebsite/ContractorSignUpWiz'
import Loadable from './ui-component/Loadable';
import Unsubscribe from './NewService/ServiceLandingPage';

const ShowMenuComponent = Loadable(lazy(() => import('./components/ShowMenuComponent')));


const routes = (isLoggedIn) => [
  {
    path: 'app',
    //element: isLoggedIn ? <DashboardLayout /> : <Navigate to="/login" />,
    //element: <CompanyPage />,
   // element: <DashboardLayout />,
    element: isLoggedIn ? <DashboardLayout /> : <StartPage />,
    children: [
      { path: 'account', element: <ItemAddPage /> },
      { path: '', element: <Navigate to="/app/dashboard" /> },  //when starting - first page
      { path: 'customers', element: <CustomerListView /> },
     // { path: 'calendar', element: <Calendar /> },
      { path: 'servicelist', element: <SRPage /> },
     //   { path: 'app/page/:ShowMenuComponent', element: <ShowMenuComponent /> },

        {
            path: "page",
            children: [
                { path: '/app/page/Branch', element: <ShowMenuComponent /> },
                { path: '/app/page/:ShowMenuComponent', element: <ShowMenuComponent /> },
            ],
        },

      // { path: 'company/:CompanyId/:CompanyName', element: <CompanyPage /> },
      //{ /app/page/Branch
      //  path: "company",
      //  element: <CompanyPage />,
      //  //children: [
      //  // // { path: '/:CompanyId/:CompanyName', element: <CompanyPage /> },
      //  //  //{ path: '/:CompanyId', element: <CompanyPage /> },
      //  //  { path: '/', element: <CompanyPage /> }
      //  //],
      //},
      //{
      //  path: "person",
      //  children: [
      //    { path: '/:CompanyId/:key/:Name', element: <PersonPage /> },
      //   // { path: '/', element: <PersonPage /> }
      //  ],
      //},
      {
        path: "/app/company",
        children: [{ path: '/app/company/:CompanyId', element: <CompanyPage /> }],
      },
      //{
      //  path: "page",
      //  children: [{ path: '/:CompanyId/:CompanyName', element: <CompanyPage /> }],
      //},
      {
        path: "list",
        children: [{ path: ':Category', element: <ListItems /> }],
      },
      {
        path: "add",
        children: [{ path: ':Category', element: <ItemAddPage /> }],
      },
      {
        path: "setup",
        children: [
          { path: 'esc', element: <ESCSetup /> },
          { path: 'pref/:Category', element: <Setup /> },
        ],
      },
      //{
      //  path: "setup",
      //  children: [{ path: '/:Category', element: <ESCSetup /> }],
      //},

      //{ path: 'listitems', element: <ListItems /> },
      { path: 'dashboard', element: <FrontPage /> }, //actual start page show content
      { path: 'products', element: <ProductListView /> },
      { path: 'settings', element: <SettingsView /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '',
    element: !isLoggedIn ? <MainLayout /> : <Navigate to="app/dashboard" />,
    children: [
      { path: '', element: <StartPage /> },
        { path: 'login', element: <StartPage /> },
     //   { path: 'ContractorSignup', element: <ContractorSignup /> },
        { path: 'ServiceRequest/:pk/:sk', element: <ServiceLandingPage /> },
        { path: 'Unsubscribe', element: <Unsubscribe /> },
      { path: 'workorder', element: <RegisterView /> }, //future workorder without signin
      { path: '404', element: <NotFoundView /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
      path: 'ContractorSignup',
      element: <ContractorSignup />,
    }
];


export default routes;
