import Chart from 'react-apexcharts';
import React, { useState, useEffect } from "react";
import { Box, Card, CardHeader, Divider, Grid, Typography } from '@mui/material';
//import { useTheme } from '@material-ui/core/styles';


const ProductChannel = (props) => {
   // const theme = useTheme();

    var options = {
        chart: {
            height: 300,
            width: 300,
            type: "bar",
            stacked: false,
            foreColor: "#333",
            background: 'transparent',
            toolbar: {
                show: false
            },
            zoom: {
                enabled: false
            }
        },
        dataLabels: {
            enabled: false
        },
        colors: ["#FF1654", "#247BA0"],
        series: [
            {
                name: "Work Progress",
                data: [25, 33, 19, 20]
            },
        ],
        stroke: {
            width: [4, 4]
        },
        plotOptions: {
            bar: {
                horizontal: true
            }
        },
        xaxis: {
            categories: ["0%", "50%", "75%", "100%"]
        },
        title: {
            text: "Progress",
            align: "center",
            margin: 20,
            offsetY: 20,
            style: {
                fontSize: "25px"
            }
        },

    };


    return (
        <Card
            variant="outlined"
            sx={{ height: '100%' }}
            {...props}
        >
            <CardHeader title="Channel" />
            <Divider />
            <Grid
                alignItems="center"
                container
                sx={{
                    justifyContent: {
                        xs: 'center'
                    },
                    py: 3
                }}
            >
                <Grid item>
                    <Chart
                        options={options}
                        series={options.series}
                        type="bar"
                        width={400}
                    />
                </Grid>

            </Grid>
        </Card>
    );
};

export default ProductChannel;