import React from 'react';
import { Drawer, Grid, Box, Typography, IconButton } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
//import makeStyles from '@mui/styles/makeStyles';
import RenderModule from './RenderModule';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { NewPageId } from '../utils/CommonGraphql';
import Container from '@mui/material/Container';


const DrawerShow = (props) => {
    // const classes = useStyles();
    const UUid = NewPageId("Drwr");
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

    console.log("DrawerModule.Props", props)
   // console.log("DrawerModule.matches", matches)

    const CloseDrawer = (e) => {
       // console.log("DrawerModule.DrawerClose")
        console.log('ListItems.DrawerModule');
        if (props.CloseDrawer) {
            props.CloseDrawer(e)
        }
    }
    return (

        <Drawer
            anchor={matches ? "left" : "bottom"}
            onClose={() => CloseDrawer(false)}
            open={props.DrawerStatus}
          //  transitionDuration={3000}
          //  PaperProps={{
         //       sx: { width: 450 },
         //   }}
            variant="temporary"
        >
            <Container maxWidth="md">
            <Grid container spacing={1} >
                <Grid item xs={12} >
                    <Grid container spacing={1} >

                        <Grid item xs={8}>
                            <Typography variant="h4" component="span">
                                {props.DrawerTitle}
                            </Typography>
                        </Grid>

                        <Grid item xs={4} >
                            <Box
                                display="flex"
                                justifyContent="flex-end"
                            >

                                <IconButton aria-label="settings" onClick={() => CloseDrawer(false)} size="large">
                                    <HighlightOffIcon />
                                </IconButton>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} >

                    <RenderModule
                        key={"rm1" + UUid}
                        LoadComponenet={props.LoadComponenet}
                        CloseDrawer={(e) => CloseDrawer(e)}
                        {...props.propss}
                    />

                </Grid>
                </Grid>
            </Container>
        </Drawer>
    )
};

export default DrawerShow;

