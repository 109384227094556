/*
 * 
 * show service request details
 * 
 * allows for action and next step
 * 
 * 
 */


import React, { useState, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { API, graphqlOperation } from "aws-amplify";
import { createSvcbotDb } from '../graphql/mutations';
//import { removeEmptyStringElements, NewPageId } from '../utils/CommonGraphql';
//import { currentTime } from '../utils/DateTime';
import { useSelector, useDispatch } from 'react-redux';
//import Accordion from '../components/Accordion';
//import {
//    //TextField,
//    //Collapse, Box,
//    //Paper,
//    Grid,
//    Backdrop,
//    CircularProgress,
//    //Card, FormGroup,
//    //CardContent,
//    //CardActions,
//    //InputLabel, Select, FormControl, MenuItem, Checkbox,
//    Typography,
//    //RadioGroup, FormControlLabel, FormLabel, Radio, IconButton, CardHeader,
//    //Switch, Tooltip, Divider
//} from '@mui/material';

import {
    //TextField,
    //Collapse, 
    Box,
    Button,
    Grid,
    Backdrop,
    CircularProgress,
    Stack,
    Card,
    //Card, FormGroup,
    CardContent,
    // CardActions,
    IconButton, Avatar, CardHeader,

    //InputLabel, Select, FormControl, MenuItem, Checkbox,
    Typography,
    //RadioGroup, FormControlLabel, FormLabel, Radio, IconButton, CardHeader,
    //Switch, Tooltip, Divider
} from '@mui/material';

import {
    removeEmptyStringElements,
    getAutoCompletedata,
    NewPageId,
    UpdateData,
    FindCategoryIcon
} from '../utils/CommonGraphql';

import DrawerShow from '../utils/DrawerModule';
//import RenderModule from '../utils/RenderModule';
import ShowCheckBoxGroup from '../components/ShowCheckBoxGroup';
import ProgressStepper from '../Setup/ProgressStepper';
//import { ProgressQuestions } from '../utils/UrgencyOptions';
//
import SRShowInformation from '../List/SRShowInformation';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutline from '@mui/icons-material/RemoveCircleOutline';
import EventDataList from '../components/EventDataList';
import ShowAvatarMessage from '../components/ShowAvatarMessage';
import MaterialGallery from '../Gallery/MaterialGallery';
import ListModule from '../List/ListModule';
import ShowPreSelect from '../components/ShowPreSelect';
import EditIcon from '@mui/icons-material/Edit';
import { red } from '@mui/material/colors';
import Skeleton from '../components/Skeleton';
import DocumentList from '../List/DocumentList';


const HtmlToReactParser = require('html-to-react').Parser;

const HTMLtoReact = (htmlInput) => {
    var htmlToReactParser = new HtmlToReactParser();
    var reactElement = htmlToReactParser.parse(htmlInput);
    return reactElement;
}

const UUid = NewPageId("SRDetail");

const useStyles = makeStyles((theme) => ({
    root: {
        // backgroundColor: theme.palette.background.dark,
        //minHeight: '100%',
        padding: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
    },
}));



export default function SimpleCard(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [DeawerOpen, SetDeawerOpen] = React.useState(false);
    const [expanded, setExpanded] = React.useState(false);
    const ContractorData = useSelector(state => state.ContractorData);
    const UserData = useSelector(state => state.UserData);
    const cid = useSelector(state => state.cid);
    const [loading, SetLoading] = useState(false);
    const initialState = {

        DetailComponent: "AddMessage",
        DrawerTitle: "Add Message",
        NewNumber: UUid,
        AddPrivateMessage: [],
        UpdateNumber: 0,
        UpdateCategory: "Meassage",
        SREvent: {},
        EventData: [],
        ProgressList: [],
        PhotoService: [],
        ShowManagerApprovalButton: false,
        ShowManagerApproved: "",
        LastStep: [],
        EquipmentList: [],
        LocationList: [],
    }
    const [state, setState] = useState(initialState);


    useEffect(() => {
        console.log("SRDetails.props", props)
        let ShowManagerApprovalButton = false;
        let ShowManagerMessage = false;
        let ManagerApprovedBy = [];
        let Read = "";
        let SREvent = props.SREvent;

        if (!SREvent.Read) {
            SREvent.Read = [];
        }

        var currentUnixTime = new Date();
        var currentTime = currentUnixTime.toISOString();

        //if (props.SREvent.ManagerApprovalNeeded) {
        //  ShowManagerApprovalButton = true;
        //  if (props.SREvent.ManagerApprovalStatus === "Pending") {
        //    ShowManagerMessage = false;
        //  }
        //} else {
        if (SREvent.ManagerApprovalStatus &&
            SREvent.ManagerApprovedBy &&
            SREvent.ManagerApprovedBy.length > 0) {
            ShowManagerMessage = true;
        }
        // }

        if (SREvent && SREvent.Read) {
            let FindRead = SREvent.Read.findIndex(x => x.sk1 === UserData.key);
            if (FindRead === -1) {
                Read = {
                    sk1: UserData.key,
                    ph1: UserData.pk1,
                    date: currentTime,
                    field1: UserData.field1,
                    field2: UserData.field2,
                    avatar: UserData.avatar,
                }
                SREvent.Read = [...SREvent.Read, Read];
                UpdateDatabase(SREvent);
            }
        }



        let PostId = props.SREvent.PostId;
        GetDatafromDB("PostId", PostId);

        setState(prevState => ({
            ...prevState,
            SREvent: SREvent,
            ShowManagerApprovalButton: SREvent.ManagerApprovalNeeded,
            ShowManagerMessage: ShowManagerMessage,
            ManagerApprovedBy: ManagerApprovedBy,
            ...SREvent
        }))


    }, [props.SREvent])

    const GetDatafromDB = async (Category, cid) => {
        try {
            SetLoading(true);
            console.log("Category, cid", Category, cid)
            const DataString = {
                Category: Category,
                FindArray: [
                    {
                        ArrayName: "DataBack",
                        //DuplicateArray: "RawData",
                        ArraySearchString: ":",
                    }
                ]
            };

            let NewData = await getAutoCompletedata(DataString, cid);

            //group each type of PostId
            console.log("SRDetails.NewData", NewData)

            let Data = NewData.DataBack;

            let LocationList = [];
            let EquipmentList = [];
            let LastStep = [];
            if (Data && Data.length > 0) {
                Data.map(each => {
                    if (each.sk1.includes("Location")) {
                        LocationList.push(each)
                    }
                    if (each.sk1.includes("Equipment")) {
                        EquipmentList.push(each)
                    }
                    if (each.sk1.includes("Post::Step")) {
                        LastStep.push(each)
                    }
                })
            }

            setState(prevState => ({
                ...prevState,
                LocationList: LocationList,
                EquipmentList: EquipmentList,
                LastStep: LastStep
            }))

            SetLoading(false);
        } catch (err) { }
    }


    const CloseDrawer = () => {
        SetDeawerOpen(false)
    }

    const UpdateDatabase = (data) => {

        var ValueToUpdate = {
            pk1: data.ServiceRequest.pk1,
            sk1: data.ServiceRequest.sk1,
            dataJSON: JSON.stringify(removeEmptyStringElements(data)),
        };

        console.log("SRDetails.ValueToUpdate", ValueToUpdate)

        UpdateData(ValueToUpdate)
            .then((up) => {
                console.log("SRDetails.Update.results", up)
                // dispatch({ type: 'TRIGGER_UPDATE', payload: true });
            })
            .catch((err) => console.log("SRDetails.failed", err));

    }

    const UpdateEvent = data => {
        console.log("SRDetails.UpdateEvent", data)
        setState(prevState => ({
            ...prevState,
            SREvent: data,
            ...data
        }))

        UpdateDatabase(data);
    }

    const UpdateEvent2 = data => {

        setState(prevState => ({
            ...prevState,
            SREvent: data,
        }))

        if (props.UpdateSREvent) {
            props.UpdateSREvent(state.SREvent)
        }

        if (props.UpdateSREventFlag) {
            props.UpdateSREventFlag(true)
        }

    }

    const AddMessage = (number, Category, Message = "") => {

        setState(prevState => ({
            ...prevState,
            DetailComponent: "AddMessage",
            DrawerTitle: Message,
            UpdateNumber: number,
            UpdateCategory: Category
        }))

        SetDeawerOpen(true)
    }

    const AcceptDecline = (number, Category, Message) => {

        setState(prevState => ({
            ...prevState,
            DetailComponent: "AcceptDecline",
            DrawerTitle: Message,
            UpdateNumber: number,
            UpdateCategory: Category
        }))

        SetDeawerOpen(true)
    }

    const ManagerApproval = (number, Category, Message) => {

        setState(prevState => ({
            ...prevState,
            DetailComponent: "ManagerApproved",
            DrawerTitle: Message,
            UpdateNumber: number,
            UpdateCategory: Category,
        }))

        SetDeawerOpen(true)
    }


    const AdditemDone = () => {
        setState(prevState => ({
            ...prevState,
            TriggerAdd: false,
        }))
    }


    const KeyArray = (data, cat) => {
        console.log("KeyArray", data, cat)
        setState(prevState => ({
            ...prevState,
            [cat]: data
        }));
    }

    const handleChange = (event, name) => {
        //let change = event.target.checked;
        console.log("Switch", event.target.checked, name)
        setState({
            ...state,
            [name]: event.target.checked,
        });
        //props.handleInclude(change, props.step.key);
    };

    //const AddPrivateMessage = () => {

    //  let NewNumber = state.NewNumber + 1;
    //  let Newbox = {
    //    key: NewNumber,
    //    MessageJSON: {}
    //  }

    //  let AddingNewBox = [...state.AddPrivateMessage, Newbox];

    //  setState({
    //    ...state,
    //    AddPrivateMessage: AddingNewBox,
    //    NewNumber: NewNumber
    //  });
    //}

    //const SelectedValues = (e, key) => {
    //  let AddPrivateMessage = state.AddPrivateMessage;
    //  AddPrivateMessage[key] = e;
    //  setState({
    //    ...state,
    //    AddPrivateMessage: AddPrivateMessage
    //  });
    //}

    const ItemHistory = (item) => {

        setState({
            ...state,
            ItemData: item,
            DetailComponent: "ShowDetailsPkSk"
        });

        console.log("SRDetails.ItemHistory", item)

        SetDeawerOpen(true);

    }

    const AddBox = () => {

        setState(prevState => ({
            ...prevState,
            NextLoadModule: "Details",
        }));

        SetDeawerOpen(true);
    }

    const AddAsset = (Category) => {

        setState(prevState => ({
            ...prevState,
            DetailComponent: "AddAsset",
            AddCategory: Category
        }));

        SetDeawerOpen(true);
    }


    console.log("SRDetails.state", state)


    return (
        <div className={classes.paper}>

            <Grid container
                spacing={2}
                direction="row"
                justifyContent="center"
                alignItems="stretch"
            >


                <Grid xs={12} item>
                    <Typography variant="h4" gutterBottom component="div">
                        {`Service: ${props.SREvent.title}`}
                    </Typography>
                </Grid>

                {/*
                    state.ShowManagerApprovalButton && (
                    <Grid xs={12} item>
                        <Button
                            onClick={() => ManagerApproval(state.EventData.length, "Approval")}
                            color="primary"
                            variant="contained"
                            startIcon={<AddCircleOutline />}
                        >
                            Manager Approval Needed
                        </Button>
                    </Grid>
                )
                */}

                <Grid xs={12} md={6} item >
                    <Card key={"crd1"} >
                        <CardHeader
                            title="Action"
                            //subheader="Information"

                            avatar={
                                <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe" >
                                    {FindCategoryIcon("decision")}
                                </Avatar>
                            }
                        />

                        <CardContent>
                            <Stack
                                direction={{ xs: 'column' }}
                                spacing={2}
                            >
                                {UserData.CustomerType === "Company" ? (
                                    <>
                                        <Button
                                            onClick={() => ManagerApproval(state.EventData.length, "Manager", "Manager Action")}
                                            color="primary"
                                            variant="contained"
                                        //  startIcon={<AddCircleOutline />}
                                        >
                                            Manager Approval
                                        </Button>
                                        <Button
                                            onClick={() => AddMessage(state.EventData.length, "Canceled", "Cancel Service")}
                                            variant="outlined" color="error"
                                        //  startIcon={<AddCircleOutline />}
                                        >
                                            Cancel Service Call
                                        </Button>
                                    </>
                                ) : (
                                    <>
                                        <Button
                                                onClick={() => AddMessage(state.EventData.length, "Accepted", "Accept Service Call")}
                                            variant="contained" color="success"
                                        //  startIcon={<AddCircleOutline />}
                                        >
                                            Accept Service
                                        </Button>

                                        <Button
                                            onClick={() => AddMessage(state.EventData.length, "Delegate", "Delegate Task")}
                                            color="primary"
                                            variant="contained"
                                        //   startIcon={<AddCircleOutline />}
                                        >
                                            Delegate
                                        </Button>
                                    </>
                                )}
                            </Stack>
                        </CardContent>
                    </Card>
                </Grid>

                {state.ShowManagerMessage && (
                    <Grid xs={12} md={6} item >
                        <Card>
                            {state.ManagerApprovedBy.map(each => (
                                <ShowAvatarMessage
                                    Message={each}
                                />
                            ))}
                        </Card>
                    </Grid>
                )}

                {state.ProgressList && (
                    <>

                        <Grid xs={12} md={6} item >
                            <ProgressStepper
                                // Data={props.ProgressList}
                                SREvent={state.SREvent}
                                title="Progress Steps"
                                ShowUpDown={false}
                                ShowEdit={true}
                                ShowReset={false}
                                CurrentStep={null} //{props.SREvent.CurrentProgressStep}
                                Category="Progress"
                                //SelectedDataReturn={(e) => KeyArray(e, "ProgressList")}
                                UpdateEvent={data => UpdateEvent(data)}
                                AddMessage={() => AddMessage(state.EventData.length, "Accept")}
                                PerPage={5}
                            />
                        </Grid>

                    </>
                )}

                {state.PhotoService && state.PhotoService.length > 0 && (
                    <Grid xs={12} md={6} item >
                        <Card key={"creedsdfff1"} >
                            <CardHeader
                                title="Images / Video"
                                avatar={
                                    <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe" >
                                        {FindCategoryIcon("Image")}
                                    </Avatar>
                                }
                            />
                            <CardContent>

                                <MaterialGallery
                                    PhotoService={state.PhotoService}
                                />

                            </CardContent>
                        </Card>
                    </Grid>
                )}

                {state.Documents && state.Documents.length > 0 && (
                    <Grid xs={12} md={6} item >
                        <DocumentList
                            DocumentList={state.Documents}
                        />
                    </Grid>
                )}

                <Grid item xs={12} md={6} >
                    <Card key={"creedsd1"} >
                        <CardHeader
                            title="Service Equipment"
                            action={
                                <IconButton aria-label="settings"
                                    onClick={() => AddAsset("Equipment")}
                                    size="large">
                                    <EditIcon />
                                </IconButton>
                            }
                            avatar={
                                <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe" >
                                    {FindCategoryIcon("Equipment")}
                                </Avatar>
                            }
                        />

                        <CardContent>
                            {state.EquipmentList.length > 0 ? (
                                <ListModule
                                    DataBack={state.EquipmentList}
                                    title="Equipment List"
                                    AddBox={e => AddBox(e)}
                                    AllowDelete={true}
                                    DetailLink={(e) => ItemHistory(e)}
                                    AllowEdit={true}
                                />
                            ) : (<Skeleton />)}

                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6} >
                    <Card key={"crkkjhd1"}>
                        <CardHeader
                            title="Service Location"
                            action={
                                <IconButton aria-label="settings"
                                    onClick={() => AddAsset("Location")}
                                    size="large">
                                    <EditIcon />
                                </IconButton>
                            }
                            avatar={
                                <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe" >
                                    {FindCategoryIcon("Location")}
                                </Avatar>
                            }
                        />

                        <CardContent>
                            {state.LocationList.length > 0 ? (
                                <ListModule
                                    DataBack={state.LocationList}
                                    title="Location List"
                                    AddBox={e => AddBox(e)}
                                    DetailLink={(e) => ItemHistory(e)}
                                    AllowDelete={true}
                                    AllowEdit={true}
                                />
                            ) : (<Skeleton />)}

                        </CardContent>
                    </Card>
                </Grid>


                <Grid xs={12} item>
                    <Card key={"crdgff1"}>
                        <CardHeader
                            title="Service Request Messages"
                            //subheader="Information"
                            action={
                                <IconButton aria-label="settings"
                                    onClick={() => AddMessage(state.EventData.length, "Message")}
                                    size="large">
                                    <EditIcon />
                                </IconButton>
                            }
                            avatar={
                                <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe" >
                                    {FindCategoryIcon("AddSMS")}
                                </Avatar>
                            }
                        />

                        <CardContent>
                            <EventDataList
                                SREvent={state.SREvent}
                                AddMessage={(number, Category) => AddMessage(number, Category)}
                                UpdateEvent2={data => UpdateEvent2(data)}
                            />
                        </CardContent>
                    </Card>
                </Grid>



                {props.SREvent.posterId && (
                    <Grid item xs={12}>
                        <SRShowInformation
                            Data={props.SREvent.posterId}
                        />
                    </Grid>
                )}

                {props.SREvent.PreSelect && props.SREvent.PreSelect.length > 0 && (
                    <Grid item xs={12}>
                        <ShowPreSelect
                            PreSelect={props.SREvent.PreSelect}
                        />
                    </Grid>
                )}


            </Grid>

            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>

            <DrawerShow
                LoadComponenet={state.DetailComponent}
                CloseDrawer={() => CloseDrawer()}
                DrawerTitle={props.DrawerTitle}
                //LoadComponenet="ItemAddPage"
                propss={{
                    CloseDrawer: () => CloseDrawer(),
                    SREvent: state.SREvent,
                    UpdateEvent: data => UpdateEvent(data),
                    CurrentProgressStep: state.CurrentProgressStep,
                    UpdateNumber: state.UpdateNumber,
                    UpdateCategory: state.UpdateCategory,
                    Category: state.AddCategory,
                    LastStep: state.LastStep,
                    DrawerTitle: state.DrawerTitle,
                    ItemData: state.ItemData
                }}
                DrawerStatus={DeawerOpen}
                // CloseDrawer={(e) => SetDeawerOpen(false)}
                OpenDrawer={(e) => SetDeawerOpen(false)}
                anchor="left"
            />
        </div>
    );
}
