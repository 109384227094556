import {
    AlertCircle as AlertCircleIcon,
    Aperture as ApertureIcon,
    BarChart as BarChartIcon,
    Lock as LockIcon,
    Truck as TruckIcon,
    Settings as SettingsIcon,
    ShoppingBag as ShoppingBagIcon,
    Map as MapIcon,
    Box as BoxIcon,
    Home as HomeIcon,
    UserPlus as UserPlusIcon,
    Users as UsersIcon,
    Phone as PhoneCall,
    Calendar as CalendarIcon,
    FileText as FileTextIcon
} from 'react-feather';

export const CompanyTabs = (props) => [
    {

        label: "Service",
        LoadComponenet: "SRPage",
        href: `/app/page/Service`,
        icon: TruckIcon,
        key: "Service",
        propss: {
            Category: "Service",
            PopulatePage: [
                {
                    LoadComponenet: "SRPage",
                    Category: "Service",
                    title: "Service",
                    size: 12,
                    CompanyId: props.CompanyId,
                    UserEditor: props.UserEditor,
                    NowEditing: props.NowEditing,
                    CustomerType: props.CustomerType,
                    ActiveCategory: "Company",
                    Activefield1: props.CompanyName,
                    ActiveKey: props.CompanyId,
                    CompanyName: props.CompanyName
                }
            ]
        }

    },
    {
        label: "Personal",
        href: `/app/page/Personal`,
        icon: UsersIcon,
        key: "Personal",
        propss: {
            Category: "Person",
            PopulatePage: [
                {
                    LoadComponenet: "ToolbarHeading",
                    Category: "Person",
                    title: "Person",
                    size: 12,
                    CompanyId: props.CompanyId,
                    UserEditor: props.UserEditor,
                    NowEditing: props.NowEditing,
                    ActiveCategory: "Company",
                    Activefield1: props.CompanyName,
                    ActiveKey: props.CompanyId,
                    CustomerType: props.CustomerType,
                    CompanyName: props.CompanyName,
                },
                {
                    LoadComponenet: "ListItems",
                    Category: "Person",
                    title: "Person",
                    size: 6,
                    CompanyId: props.CompanyId,
                    UserEditor: props.UserEditor,
                    NowEditing: props.NowEditing,
                    ActiveCategory: "Company",
                    Activefield1: props.CompanyName,
                    ActiveKey: props.CompanyId,
                    CustomerType: props.CustomerType,
                    CompanyName: props.CompanyName,
                }
            ]
        }
    },
    {

        label: "Equipment",
        href: `/app/page/Personal`,
        icon: BoxIcon,
        key: "Equipment",
        propss: {
            Category: "Equipment",
            PopulatePage: [
                {
                    LoadComponenet: "ToolbarHeading",
                    Category: "Equipment",
                    title: "Equipment",
                    size: 12,
                    CompanyId: props.CompanyId,
                    UserEditor: props.UserEditor,
                    NowEditing: props.NowEditing,
                    ActiveCategory: "Company",
                    Activefield1: props.CompanyName,
                    ActiveKey: props.CompanyId,
                    CustomerType: props.CustomerType,
                    CompanyName: props.CompanyName,
                },
                {
                    LoadComponenet: "ListItems",
                    Category: "Equipment",
                    title: "Equipment",
                    size: 6,
                    CompanyId: props.CompanyId,
                    UserEditor: props.UserEditor,
                    ActiveCategory: "Company",
                    Activefield1: props.CompanyName,
                    ActiveKey: props.CompanyId,
                    NowEditing: props.NowEditing,
                    CustomerType: props.CustomerType,
                    CompanyName: props.CompanyName,
                }
            ]
        }
    },
    {
        label: "Location",
        href: `/app/page/Personal`,
        icon: MapIcon,
        key: "Location",
        propss: {
            Category: "Location",
            PopulatePage: [
                {
                    LoadComponenet: "ToolbarHeading",
                    Category: "Location",
                    title: "Location",
                    size: 12,
                    CompanyId: props.CompanyId,
                    UserEditor: props.UserEditor,
                    NowEditing: props.NowEditing,
                    ActiveCategory: "Company",
                    Activefield1: props.CompanyName,
                    ActiveKey: props.CompanyId,
                    CustomerType: props.CustomerType,
                    CompanyName: props.CompanyName,
                },
                {
                    LoadComponenet: "ListItems",
                    Category: "Location",
                    title: "Location",
                    size: 6,
                    CompanyId: props.CompanyId,
                    UserEditor: props.UserEditor,
                    ActiveCategory: "Company",
                    Activefield1: props.CompanyName,
                    ActiveKey: props.CompanyId,
                    NowEditing: props.NowEditing,
                    CustomerType: props.CustomerType,
                    CompanyName: props.CompanyName,
                }
            ]
        }

    },
    {
        label: "Documents",
        href: `/app/page/Personal`,
        icon: FileTextIcon,
        key: "Documents",
        propss: {
            Category: "Documents",
            PopulatePage: [
                {
                    LoadComponenet: "DocumentPage",
                    Category: "Documents",
                    title: "Documents",
                    size: 12,
                    CompanyId: props.CompanyId,
                    UserEditor: props.UserEditor,
                    NowEditing: props.NowEditing,
                    ActiveCategory: "Company",
                    Activefield1: props.CompanyName,
                    ActiveKey: props.CompanyId,
                    CustomerType: props.CustomerType,
                    CompanyName: props.CompanyName,
                }
            ]
        }

    },
    {

        label: "Setup",
        LoadComponenet: "SetupPage",
        href: `/app/page/Setup`,
        icon: SettingsIcon,
        key: "Setup",
        propss: {
            Category: "Setup",
            PopulatePage: [
                {
                    LoadComponenet: "SetupPage",
                    Category: "Setup",
                    title: "Setup",
                    size: 12,
                    CompanyId: props.CompanyId,
                    UserEditor: props.UserEditor,
                    NowEditing: props.NowEditing,
                    ActiveCategory: "Company",
                    Activefield1: props.CompanyName,
                    ActiveKey: props.CompanyId,
                    CustomerType: props.CustomerType,
                    CompanyName: props.CompanyName,
                    ItemData: props.ItemData
                }
            ]
        }
    },
    {
        label: "Shift",
        LoadComponenet: "ListItems",  //"ListSetupOptions",
        href: `/app/page/Shift`,
        icon: CalendarIcon,
        key: "Shift",
        propss: {
            Category: "ESC",
            PopulatePage: [
                {
                    LoadComponenet: "CalendarBox",
                    ListType: "CalendarBox",
                    Category: "ESC",
                    title: "ESC",
                    size: 12,
                    CompanyId: props.CompanyId,
                    NowEditing: props.NowEditing,
                    UserEditor: props.UserEditor,
                    ActiveCategory: "Company",
                    Activefield1: props.CompanyName,
                    ActiveKey: props.CompanyId,
                    CustomerType: props.CustomerType,
                    CompanyName: props.CompanyName,
                    disabled: false,
                    Heading: `${props.CompanyName} On-Call Shift`
                }
            ]
        }
    }
];

export const PersonTabs = (props) => [
    //{
    //    label: "History",
    //    LoadComponenet: "SRPage",
    //    href: `/app/page/History`,
    //    icon: AlertCircleIcon,
    //    key: "History",
    //    propss: {
    //        Category: "History",
    //        PopulatePage: [
    //            {
    //                LoadComponenet: "SRPage",
    //                ListType: "History",
    //                Category: "History",
    //                title: "History",
    //                size: 12,
    //                CompanyId: props.CompanyId,
    //                ActiveCategory: props.ActiveCategory,
    //                Activefield1: props.Activefield1,
    //                ActiveKey: props.ActiveKey,
    //                NowEditing: props.NowEditing,
    //                UserEditor: props.UserEditor,
    //                CustomerType: props.CustomerType,
    //                CompanyName: props.CompanyName
    //            }
    //        ]
    //    }
    //},

    {
        label: "My Shift",
        LoadComponenet: "ListItems",  //"ListSetupOptions",
        href: `/app/page/Shift`,
        icon: CalendarIcon,
        key: "Shift",
        propss: {
            Category: "ESC",
            PopulatePage: [
                {
                    LoadComponenet: "PersonCalendarBox",
                    ListType: "PersonCalendarBox",
                    Category: "shift",
                    title: "shift",
                    size: 12,
                    CompanyId: props.CompanyId,
                    NowEditing: props.NowEditing,
                    ActiveCategory: props.ActiveCategory,
                    Activefield1: props.Activefield1,
                    ActiveKey: props.ActiveKey,
                    UserEditor: props.UserEditor,
                    CustomerType: props.CustomerType,
                    CompanyName: props.CompanyName,
                    disabled: false,
                    Heading: `${props.Activefield1} On-Call Shift`
                },
                {
                    LoadComponenet: "CalendarBox",
                    ListType: "CalendarBox",
                    Category: "ESC",
                    title: "ESC",
                    size: 12,
                    CompanyId: props.CompanyId,
                    NowEditing: props.NowEditing,
                    UserEditor: props.UserEditor,
                    ActiveCategory: props.ActiveCategory,
                    Activefield1: props.Activefield1,
                    ActiveKey: props.ActiveKey,
                    CustomerType: props.CustomerType,
                    CompanyName: props.CompanyName,
                    disabled: false,
                    Heading: `${props.Activefield1} On-Call Shift`
                }
            ]
        }
    },

    {

        label: "Setup",
        LoadComponenet: "SetupPage",  //"ListSetupOptions",
        href: `/app/page/Setup`,
        icon: SettingsIcon,
        key: "Setup",
        propss: {
            Category: "Setup",
            PopulatePage: [
                {
                    LoadComponenet: "SetupPage",
                    Category: "Setup",
                    title: "Setup",
                    size: 12,
                    CompanyId: props.CompanyId,
                    ActiveCategory: props.ActiveCategory,
                    Activefield1: props.Activefield1,
                    ActiveKey: props.ActiveKey,
                    UserEditor: props.UserEditor,
                    NowEditing: props.NowEditing,
                    CustomerType: props.CustomerType,
                    CompanyName: props.CompanyName,
                    ItemData: props.ItemData
                }
            ]
        }
    },



];



export const HQTabs = (props) => [

    {
        label: "Branch Locations",
        href: `/app/page/Branch`,
        icon: MapIcon,
        key: "Branch",
        propss: {
            Category: "Branch",
            PopulatePage: [
                {
                    LoadComponenet: "ToolbarHeading",
                    Category: "Branch",
                    title: "Branch",
                    size: 12,
                    CompanyId: props.BranchId,
                    NowEditing: props.NowEditing,
                    UserEditor: props.UserEditor,
                    CustomerType: props.CustomerType,
                    CompanyName: props.ContractorName
                },
                {
                    LoadComponenet: "ListItems",
                    Category: "Branch",
                    title: "Branch",
                    size: 6,
                    CompanyId: props.HQId,
                    NowEditing: props.NowEditing,
                    UserEditor: props.UserEditor,
                    CustomerType: props.CustomerType,
                    CompanyName: props.ContractorName,
                },
                {
                    LoadComponenet: "Chart1",
                    Category: "Branch",
                    title: "Branch",
                    size: 6,
                    CompanyId: props.HQId,
                    NowEditing: props.NowEditing,
                    UserEditor: props.UserEditor,
                    CustomerType: props.CustomerType,
                    CompanyName: props.ContractorName,
                }
            ]
        }
    },

];


export const AssetTabs = (props) => [
    //{
    //    label: "History",
    //    LoadComponenet: "SRPage",
    //    href: `/app/page/History`,
    //    icon: PhoneCall,
    //    key: "History",
    //    propss: {
    //        CompanyId: props.CompanyId,
    //        NowEditing: props.NowEditing,
    //        UserEditor: props.UserEditor,
    //        CustomerType: props.CustomerType,
    //        CompanyName: props.CompanyName,
    //        Category: props.Category
    //    }
    //},
    //{
    //    label: "Details",
    //    LoadComponenet: "ShowDetails",
    //    href: `/app/page/Details`,
    //    icon: PhoneCall,
    //    key: "Details",
    //    propss: {
    //        CompanyId: props.CompanyId,
    //        NowEditing: props.NowEditing,
    //        UserEditor: props.UserEditor,
    //        CustomerType: props.CustomerType,
    //        CompanyName: props.CompanyName,
    //        Category: props.Category
    //    }
    //},

    {
        label: "Details",
        LoadComponenet: "ShowDetailsPkSk",
        href: `/app/page/History`,
        icon: AlertCircleIcon,
        key: "History",
        propss: {
            Category: "History",
            PopulatePage: [
                {
                    LoadComponenet: "ShowDetailsPkSk",
                    ListType: "History",
                    Category: "History",
                    title: "History",
                    size: 12,
                    CompanyId: props.CompanyId,
                    ActiveCategory: props.ActiveCategory,
                    Activefield1: props.Activefield1,
                    ActiveKey: props.ActiveKey,
                    NowEditing: props.NowEditing,
                    UserEditor: props.UserEditor,
                    CustomerType: props.CustomerType,
                    CompanyName: props.CompanyName,
                    ItemData: props.ItemData
                }
            ]
        }
    },
    //{

    //    label: "Setup",
    //    LoadComponenet: "SetupPage",  //"ListSetupOptions",
    //    href: `/app/page/Setup`,
    //    icon: SettingsIcon,
    //    key: "Setup",
    //    propss: {
    //        Category: "Setup",
    //        PopulatePage: [
    //            {
    //                LoadComponenet: "SetupPage",
    //                Category: "Setup",
    //                title: "Setup",
    //                size: 12,
    //                CompanyId: props.CompanyId,
    //                ActiveCategory: props.ActiveCategory,
    //                Activefield1: props.Activefield1,
    //                ActiveKey: props.ActiveKey,
    //                UserEditor: props.UserEditor,
    //                NowEditing: props.NowEditing,
    //                CustomerType: props.CustomerType,
    //                CompanyName: props.CompanyName,
    //                ItemData: props.ItemData
    //            }
    //        ]
    //    }
    //},

];

export const ContractorTabs = (props) => [
    {
        label: "Service",
        LoadComponenet: "SRPage",
        href: `/app/page/Service`,
        icon: TruckIcon,
        key: "Service",
        propss: {
            Category: "Service",
            PopulatePage: [
                {
                    LoadComponenet: "SRPage",
                    Category: "Service",
                    title: "Service",
                    size: 12,
                    CompanyId: props.BranchId,
                    NowEditing: props.NowEditing,
                    UserEditor: props.UserEditor,
                    CustomerType: props.CustomerType,
                    CompanyName: props.ContractorName,
                }
            ]
        }
    },
    {
        label: "Map View",
        LoadComponenet: "MapPage",
        href: `/app/page/Service`,
        icon: MapIcon,
        key: "Service",
        propss: {
            Category: "MapView",
            PopulatePage: [
                {
                    LoadComponenet: "MapPage",
                    Category: "MapView",
                    title: "Map View",
                    size: 12,
                    CompanyId: props.BranchId,
                    NowEditing: props.NowEditing,
                    UserEditor: props.UserEditor,
                    CustomerType: props.CustomerType,
                    CompanyName: props.ContractorName,
                }
            ]
        }
    },
    {
        label: "Personal",
        href: `/app/page/Personal`,
        icon: UsersIcon,
        key: "Personal",
        propss: {
            Category: "Person",
            PopulatePage: [
                {
                    LoadComponenet: "ToolbarHeading",
                    Category: "Person",
                    title: "Person",
                    size: 12,
                    CompanyId: props.BranchId,
                    NowEditing: props.NowEditing,
                    UserEditor: props.UserEditor,
                    ActiveCategory: props.ActiveCategory,
                    Activefield1: props.Activefield1,
                    ActiveKey: props.ActiveKey,
                    CustomerType: props.CustomerType,
                    CompanyName: props.ContractorName
                },
                {
                    LoadComponenet: "ListItems",
                    Category: "Person",
                    title: "Person",
                    size: 6,
                    CompanyId: props.BranchId,
                    NowEditing: props.NowEditing,
                    UserEditor: props.UserEditor,
                    CustomerType: props.CustomerType,
                    CompanyName: props.ContractorName,
                }
            ]
        }
    },
    {
        label: "Accounts",
        LoadComponenet: "ListItems",
        href: `/app/page/Accounts`,
        icon: ApertureIcon,
        key: "Accounts",
        propssdd: {
            CompanyId: props.ContractorId,
            NowEditing: props.NowEditing,
            UserEditor: props.UserEditor,
            CompanyName: props.ContractorName,
            Category: "Company"
        },

        propss: {
            Category: "Company",
            PopulatePage: [
                {
                    LoadComponenet: "ToolbarHeading",
                    Category: "Company",
                    title: "Company",
                    size: 12,
                    CompanyId: props.BranchId,
                    NowEditing: props.NowEditing,
                    UserEditor: props.UserEditor,
                    CustomerType: props.CustomerType,
                    CompanyName: props.ContractorName
                },
                {
                    LoadComponenet: "ListItems",
                    Category: "Company",
                    title: "Company",
                    size: 6,
                    CompanyId: props.BranchId,
                    NowEditing: props.NowEditing,
                    UserEditor: props.UserEditor,
                    CustomerType: props.CustomerType,
                    CompanyName: props.ContractorName
                }
            ]
        }
    },

    {
        label: "Setup",
        LoadComponenet: "SetupPage",
        href: `/app/page/Setup`,
        icon: SettingsIcon,
        key: "Setup",
        propss: {
            Category: "Setup",
            PopulatePage: [
                {
                    LoadComponenet: "SetupPage",
                    Category: "Setup",
                    title: "Setup",
                    size: 12,
                    CompanyId: props.BranchId,
                    NowEditing: props.NowEditing,
                    UserEditor: props.UserEditor,
                    ActiveCategory: "AddSMS",
                    Activefield1: props.CompanyName,
                    ActiveKey: props.CompanyId,
                    CustomerType: props.CustomerType,
                    CompanyName: props.ContractorName,
                    ItemData: props.ItemData
                }
            ]
        }
    },
    {
        label: "Shift",
        LoadComponenet: "ListItems",
        href: `/app/page/Shift`,
        icon: CalendarIcon,
        key: "Shift",
        propss: {
            Category: "ESC",
            PopulatePage: [
                {
                    LoadComponenet: "CalendarBox",
                    ListType: "CalendarBox",
                    Category: "AddSMS",
                    title: "ESC",
                    size: 12,
                    CompanyId: props.BranchId,
                    NowEditing: props.NowEditing,
                    UserEditor: props.UserEditor,
                    CustomerType: props.CustomerType,
                    CompanyName: props.ContractorName,
                    disabled: false,
                    Heading: `${props.ContractorName} On-Call Shift`
                }
            ]
        }
    }
];


export const HQFrontPage = (props) => [

    {
        LoadComponenet: "ToolbarHeading",
        Category: "Branch",
        title: "Branch",
        size: 12,
        CompanyId: props.CompanyId,
        NowEditing: props.NowEditing,
        UserEditor: props.UserEditor,
        CustomerType: props.CustomerType,
        CompanyName: props.CompanyName
    },
    {
        LoadComponenet: "ListItems",
        Category: "Branch",
        title: "Branch",
        size: 6,
        CompanyId: props.CompanyId,
        NowEditing: props.NowEditing,
        UserEditor: props.UserEditor,
        CustomerType: props.CustomerType,
        CompanyName: props.CompanyName,
    },
    {
        LoadComponenet: "Chart1",
        Category: "Branch",
        title: "Branch",
        size: 6,
        CompanyId: props.CompanyId,
        NowEditing: props.NowEditing,
        UserEditor: props.UserEditor,
        CustomerType: props.CustomerType,
        CompanyName: props.CompanyName,
    }
];

export const ContractorFrontPage = (props) => [
    {
        LoadComponenet: "SRPage",
        Category: "Service",
        title: "Service",
        size: 12,
        CompanyId: props.CompanyId,
        NowEditing: props.NowEditing,
        UserEditor: props.UserEditor,
        CustomerType: props.CustomerType,
        CompanyName: props.CompanyName,
    }
];

export const CompanyFrontPage = (props) => [
    //{
    //    LoadComponenet: "ListItems",
    //    Category: "ServiceCalls",
    //    title: "Recent Service Calls",
    //    size: 6,
    //    CompanyId: props.CompanyId,
    //    NowEditing: props.NowEditing,
    //    UserEditor: props.UserEditor,
    //    CustomerType: props.CustomerType,
    //    CompanyName: props.CompanyName,
    //},
    {
        LoadComponenet: "Chart1",
        Category: "Branch",
        title: "Branch",
        size: 6,
        CompanyId: props.CompanyId,
        NowEditing: props.NowEditing,
        UserEditor: props.UserEditor,
        CustomerType: props.CustomerType,
        CompanyName: props.CompanyName,
    },
    {
        LoadComponenet: "SRPage",
        Category: "Service",
        title: "Service",
        size: 12,
        CompanyId: props.CompanyId,
        NowEditing: props.NowEditing,
        UserEditor: props.UserEditor,
        CustomerType: props.CustomerType,
        CompanyName: props.CompanyName,
    }
];

