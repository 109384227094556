import React, { useState, useEffect } from 'react';
import {
    TextField, Backdrop,
    CircularProgress, Button, Typography, Grid
} from '@mui/material';
//import Autocomplete from '@mui/material/Autocomplete';
import { getAutoCompletedata, NewPageId } from '../utils/CommonGraphql';
import DrawerShow from '../utils/DrawerModule';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

const filter = createFilterOptions();

export default function FreeSoloCreateOptionDialog(props) {

    let ResetValue = {
        SearchWords: "",
        filed1: ""
    }

    const [value, setValue] = React.useState(null);
    const [valueShow, setValueShow] = React.useState(null);
    const [open, toggleOpen] = React.useState(false);

    const initialState = {
        DataBack: [],
        Category: "",
        ShowSelected: false,
        ShowAddButton: false,
        anchor: "left",
        SelectedValue: "",
        Selectedtitle: "",
    }
    const [state, setState] = useState(initialState);
    const [loading, SetLoading] = useState(false);

    useEffect(() => {
        GetDatafromDB(props.Category, props.CompanyId);
        setState(prevState => ({
            ...prevState,
            Category: props.Category
        }))

        if (props.SearchWords && props.SearchWords !== "x") {
            setValueShow(props.SearchWords)
        }

        console.log("Testing.AutoComplete.Props", props);
    }, [props.Category])

    const GetDatafromDB = async (Category, cid) => {
        SetLoading(true);
        let NewData = [];

        const DataString = {
            Category: Category,
            FindArray: [
                {
                    ArrayName: "DataBack",
                    ArraySearchString: ":",
                }
            ]
        };

        let NewData2 = await getAutoCompletedata(DataString, cid);
        NewData = await NewData2;

        console.log("Autocomplete.NewData", NewData);
        setState(prevState => ({ ...prevState, ...NewData }))

        SetLoading(false);
    }

    const CloseDrawer = (data) => {
        if (data && data !== undefined) {
            handleClose(data);
            GetDatafromDB(props.Category, props.CompanyId);
        } else {
            toggleOpen(false);
        }
        
    }

    const handleClose = (data) => {

        if (data && data !== undefined) {
            setValue(data)
            setValueShow(data.field1)
            console.log("AutoComplete.data", data)

            if (props.onSubmit) {
                props.onSubmit(data);
            }

            //Selection is used to bring all the data back
            if (props.Selection) {
                props.Selection(data);
            }
        }

        toggleOpen(false);
    };

    const ReturnBackData = (e) => {
        //Selection is used to bring all the data back
        console.log("Autocomplete.ReturnBackData", e);

        handleClose(e);

        if (props.Selection && e) {
            props.Selection(e);
        }
    }

    console.log("AutoComplete.state", state)
    console.log("AutoComplete.props", props)

    return (
        <React.Fragment>
            <Grid
                container
                spacing={1}
            >

                {valueShow && valueShow !== null && (
                    <Grid item xs={12} >
                        <Typography
                            sx={{ display: 'inline' }}
                            component="span"
                            variant="body2"
                            color="text.primary"
                        >
                            {`Selected: ${valueShow}`}
                        </Typography>
                    </Grid>
                )}


                {state.DataBack && state.DataBack.length > 0 ? (
                    <Grid item xs={12} >
                        <Autocomplete
                            value={value}
                            onChange={(event, newValue) => {
                                if (typeof newValue === 'string') {
                                    setTimeout(() => {
                                        toggleOpen(true);
                                    });
                                } else if (newValue && newValue.inputValue) {
                                    toggleOpen(true);
                                } else {
                                    handleClose(newValue)
                                }
                            }}
                            filterOptions={(options, params) => {
                                let filtered = filter(options, params);
                                if (params.inputValue !== '') {
                                    filtered.push({
                                        inputValue: params.inputValue,
                                        field1: `Add ${props.Category}`,
                                    });
                                }

                                return filtered;
                            }}
                            id="free-solo-dialog-demo"
                            options={state.DataBack}
                            getOptionLabel={(option) => {
                                // e.g value selected with enter, right from the input

                                if (typeof option === 'string') {
                                    return option;
                                }
                                if (option.inputValue) {
                                    return option.inputValue;
                                }
                                return option.SearchWords;
                            }}
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            renderOption={(props, option) => <li {...props}>{option.field1}</li>}
                            freeSolo
                            sx={{ width: '100%' }}
                            renderInput={(params) => <TextField {...params} label="Search or Add..." />}
                        />
                    </Grid>

                ) : (
                    <Grid item xs={12} >
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => toggleOpen(true)}
                        >
                            {`Add ${props.Category}`}
                        </Button>
                    </Grid>
                )}

            </Grid>
            <DrawerShow
                //LoadComponenet="Shift"
                LoadComponenet="ItemAddPage"
                propss={{
                    Category: props.Category,
                    CompanyId: props.CompanyId,
                    key: props.id,
                    ImageUpload: true,
                    CloseDrawer: (e) => CloseDrawer(e),
                    ReturnBackData: (e) => ReturnBackData(e)
                }}
                //Category={props.Category}
                //key={props.id}
                DrawerStatus={open}
                CloseDrawer={(e) => CloseDrawer(e)}
                OpenDrawer={(e) => handleClose(e)}
                anchor={state.anchor || "left"}
            />



            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            // onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

        </React.Fragment>
    );
}
