import React from 'react';
//import { useSelector } from 'react-redux';
const AWS = require("aws-sdk");
AWS.config.region = 'us-east-1'; // Region
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: "us-east-1:8b6c0cb7-6827-4016-8b5f-834c9d3d790c",
});
//const SES = new AWS.SES();
const UTF8CHARSET = 'UTF-8';

export const SendEmail = (event) => {

    try {
        console.log("SubscriptionAdd1.event", event);
       // const ContractorData = useSelector(state => state.ContractorData);
        
        let ReturnMessage = "";
       
        if (event && event.PersonList && event.PersonList.length > 0) {
                      
            let EmailPrems = {
                ContractorAddress: "",
                ContractorPhone: "",
                ContractorLogo: "",
                ContractorName: "",

                UnsubscribeLink: "",
                ActionButton: "",
                ActionButtonTitle: "Take Action",

                PhotoService: [],
                Documents: [],
                SpecialInstructions: "",
                PreSelect: [],
                title: "",
                postHTML: "",
                PersonList: [],
                postTEXT: "",
                ReplyEmail: "no-reply@service-call-cloud.com",
                PostId: "",
                ActionButtonShow: true,
                ...event
            };

            console.log("SubscriptionAdd1.EmailPrems", EmailPrems);

            //const destination = {
            //    ToAddresses: event.PersonList
            //};
            
            let Emailtemplatebe = EmailTemplate(EmailPrems);

            const emailParams = {
               // Destination: destination,
                Destination: {
                    ToAddresses: event.PersonList
                },
                Message: {
                    Body: {
                        Html: { Charset: UTF8CHARSET, Data: Emailtemplatebe },
                        Text: { Charset: UTF8CHARSET, Data: event.postTEXT }
                    },
                    Subject: {
                        Charset: UTF8CHARSET,
                        Data: event.title
                    }
                },
                Source: event.ReplyEmail
            };

            if (event.ReplyEmail && Array.isArray(event.ReplyEmail)) {
                emailParams.ReplyToAddresses = event.ReplyEmail;
            }
            console.log("SubscriptionAdd1.emailParams", emailParams);

            var sendPromise = new AWS.SES({ apiVersion: '2010-12-01' }).sendEmail(emailParams).promise();

            // Handle promise's fulfilled/rejected states
            sendPromise.then(
                function (data) {
                    console.log(data.MessageId);
                }).catch(
                    function (err) {
                        console.error(err, err.stack);
                    });

            //SES.sendEmail(emailParams).promise()
            //    .then(() => {
            //       // ReturnMessage = "Email Successfully Sent";
            //        console.log("Email Successfully Sent", ReturnMessage);
            //    })
            //    .catch(err => {
            //       // ReturnMessage = "There was an error in sending the email. Please try again";
            //        console.log("SubscriptionAdd1.err", err);
            //    });
        }

        return ReturnMessage;

    } catch (err) { console.log("UrgencyDetails.err", err); }

};

   
const EmailTemplate = event => {

    let ContractorName = event.ContractorName;
    let ContractorAddress = event.ContractorAddress;
    let ContractorPhone = event.ContractorPhone;
    let ActionButton = event.ActionButton;
    let ContractorLogo = event.ContractorLogo;
    let ActionButtonTitle = event.ActionButtonTitle;
    let PhotoService = event.PhotoService;
    let Documents = event.Documents;
    let SpecialInstructions = event.SpecialInstructions;
    let PreSelect = event.PreSelect;
    let UnsubscribeLink = event.UnsubscribeLink;
    let Heading = event.title;
    let Message = event.postHTML;
    let ActionButtonShow = event.ActionButtonShow;

    let BaseInfo = BaseInfoList => {
        // console.log("BaseInfoList", BaseInfoList)
        let NewBulletItem = "";
        if (BaseInfoList && BaseInfoList.length > 0) {
            BaseInfoList.map((each, indd) => {
                if (each.VariableInput) {
                    NewBulletItem += `<li>${each.field1}</li>`;
                }
            });
        }
        return NewBulletItem;
    };


    let GetContactList = ContactList => {

        let GetContactListString = "";
        ContactList.map((each, indd) => {

            GetContactListString += `<tr>
          <td class="attributes_item">
            <span class="f-fallback">
                                    <h3>${each.role}</h3>
                                    </span>
          </td>
        </tr>
        <tr><td class="attributes_item">
            <span class="f-fallback">
              `;

            if (each.BaseInfo && each.BaseInfo.length > 0) {
                GetContactListString += `<ul>
                ${BaseInfo(each.BaseInfo)}`;
            }

            if (each.ExtraInfo && each.ExtraInfo.length > 0) {
                GetContactListString += `${BaseInfo(each.ExtraInfo)}`;
            }

            GetContactListString += `</ul>`;
        });

        GetContactListString += `</span>
          </td>
        </tr>
`;

        return GetContactListString;
    };

    let PreSelectCode = "";

    if (Documents && Documents.length > 0) {
        PreSelectCode += `<table class="attributes" width="100%" cellpadding="0" cellspacing="0" role="presentation">
                          <tr><td class="attributes_content">
                          <table width="100%" cellpadding="0" cellspacing="0" role="presentation">
                          <tr><td class="attributes_item"><span class="f-fallback">
                          <h2>Documents</h2></td></tr><tr><td class="attributes_item">
                          <span class="f-fallback"><table style="width:100%">`;
        Documents.map(each => {
            if (each.filetypes === 1) {
                PreSelectCode += `<tr><td>
<img width="30px" class="Slides" src="https://svcoptengimagebucket160759-dev.s3.amazonaws.com/zipIcon.png" />
</td>`;
            } else if (each.filetypes === 10) {
                PreSelectCode += `<tr><td><img width="30px" class="Slides" src="https://svcoptengimagebucket160759-dev.s3.amazonaws.com/ContactCard.png"  /></td>`;
            }
         else {
                PreSelectCode += `<tr><td><img width="30px" class="Slides" src="https://svcoptengimagebucket160759-dev.s3.amazonaws.com/pdfIcon.png" /></td>`;
    }
            PreSelectCode += `<td><a href="${each.ImageFullPath}" target="_blank">
                                ${each.caption}
                                </a>
                                </td></tr>`;
        })

        PreSelectCode += `</table></td></tr></table></td></tr></table>`;
    }

    if (SpecialInstructions && SpecialInstructions !== "") {
        PreSelectCode += `<table class="attributes" width="100%" cellpadding="0" cellspacing="0" role="presentation">
                          <tr><td class="attributes_content">
                          <table width="100%" cellpadding="0" cellspacing="0" 
role="presentation">
                          <tr><td class="attributes_item"><span class="f-fallback">
                          <h2>Special Instructions</h2></td></tr><tr><td class="attributes_item">
                          <span class="f-fallback">
                          ${SpecialInstructions}
                          </td></tr></table></td></tr></table>`;
    }

    if (PreSelect && PreSelect.length > 0) {
        PreSelect.map((eachPreSelect, indd) => {
            // console.log("eachPreSelect", indd, eachPreSelect)
            PreSelectCode += `<h2>${eachPreSelect.field1}</h2>
<table class="attributes" width="100%" cellpadding="0" cellspacing="0" role="presentation">
  <tr>
    <td class="attributes_content">
      <table width="100%" cellpadding="0" cellspacing="0" role="presentation">
${GetContactList(eachPreSelect.ContactList)}
</table>
    </td>
  </tr>
</table>
`;
        });
    }

    let ShowButton = "";
    if (ActionButtonShow) {
        ShowButton = `<table width="100%" border="0" cellspacing="0" cellpadding="0" role="presentation">
            <tr> <td align="center"> <a href="${ActionButton}" class="f-fallback button" target="_blank">
                ${ActionButtonTitle}
            </a> </td> </tr> </table>`;
    }

    let ImagesBlock = "";

    const ImagesBlocks = imagess => {
        let ImageList = "";
        imagess.map((image, ind) => {
            if (image.MediaContentType && image.MediaContentType.includes("video")) {

                ImageList += `<table width="100%" style="max-width:230px;"><tr><td>
<a href="${image.ImageFullPath}" target="_blank">
<video width="220" controls>
<source width="220px" class="Slides" src="${image.ImageFullPath}" type="${image.MediaContentType}">
</video>
</a>
</td>
</tr></table>`;

            } else {
                ImageList += `<table width="100%" style="max-width:230px;"><tr><td>
<a href="${image.ImageFullPath}" target="_blank">
<img width="220px" class="Slides" src="${image.ImageFullPath}" />
</a>
</td>
</tr></table>`;
            }
        });
        return ImageList;
    };

    if (PhotoService && PhotoService.length > 0) {
        ImagesBlock += `<table class="attributes" width="100%" cellpadding="0" cellspacing="0" role="presentation">
                          <tr><td class="attributes_content"><table width="100%" cellpadding="0" cellspacing="0" role="presentation">
                          <tr><td class="attributes_item" >
<span class="f-fallback">
<h2>Images</h2></td>
                          </tr><tr><td class="attributes_item" align="center">
<span class="f-fallback" align="center">`;
        ImagesBlock += `<div class="flex-container" align="center">${ImagesBlocks(PhotoService)}</div>`;
        ImagesBlock += `</td></tr></table></td></tr></table>`;
    }


    let Logo = "";
    if (ContractorLogo && ContractorLogo !== "") {

        Logo += `<a href="${ActionButton}" target="_blank">
        <img height="100px" style="vertical-align:middle; height: 100px; margin-bottom:.9em" src="${ContractorLogo}" alt="${ContractorName}" />
    </a>`;
    } else {
        Logo += `<span style="font-size: 1.8em; font-weight:600; margin-top:3em">${ContractorName}</span>`;
    }



    return (`
    <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
    <html>
    <head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
        <title></title>
        <style type="text/css" rel="stylesheet" media="all">

            @import url("https://fonts.googleapis.com/css?family=Nunito+Sans:400,700&display=swap");

            body {
                width: 100% !important;
                height: 100%;
                margin: 0;
                -webkit-text-size-adjust: none;
            }

            a {
                color: #3869D4;
            }

                a img {
                    border: none;
                }

            td {
                word-break: break-word;
            }

.flex-container {
display: flex;
flex-wrap: wrap;
align-items: center;
  justify-content: center;
}

.flexbox {
width: 250px;
margin: 10px;
}

            .preheader {
                display: none !important;
                visibility: hidden;
                mso-hide: all;
                font-size: 1px;
                line-height: 1px;
                max-height: 0;
                max-width: 0;
                opacity: 0;
                overflow: hidden;
            }
            /* Type ------------------------------ */

            body,
            td,
            th {
                font-family: "Nunito Sans", Helvetica, Arial, sans-serif;
            }

            h1 {
                margin-top: 0;
                color: #333333;
                font-size: 22px;
                font-weight: bold;
                text-align: left;
            }

            h2 {
                margin-top: 0;
                color: #333333;
                font-size: 16px;
                font-weight: bold;
                text-align: left;
            }

            h3 {
                margin-top: 0;
                color: #333333;
                font-size: 14px;
                font-weight: bold;
                text-align: left;
            }

            td,
            th {
                font-size: 16px;
            }

            p,
            ul,
            ol,
            blockquote {
                margin: .4em 0 1.1875em;
                font-size: 16px;
                line-height: 1.625;
            }

                p.sub {
                    font-size: 13px;
                }
            /* Utilities ------------------------------ */

            .align-right {
                text-align: right;
            }

            .align-left {
                text-align: left;
            }

            .align-center {
                text-align: center;
            }
            /* Buttons ------------------------------ */

            .button {
                background-color: #3869D4;
                border-top: 10px solid #3869D4;
                border-right: 18px solid #3869D4;
                border-bottom: 10px solid #3869D4;
                border-left: 18px solid #3869D4;
                display: inline-block;
                color: #FFFFFF;
                text-decoration: none;
                border-radius: 3px;
                box-shadow: 0 2px 3px rgba(0, 0, 0, 0.16);
                -webkit-text-size-adjust: none;
                box-sizing: border-box;
            }

            .button--green {
                background-color: #22BC66;
                border-top: 10px solid #22BC66;
                border-right: 18px solid #22BC66;
                border-bottom: 10px solid #22BC66;
                border-left: 18px solid #22BC66;
            }

            .button--red {
                background-color: #FF6136;
                border-top: 10px solid #FF6136;
                border-right: 18px solid #FF6136;
                border-bottom: 10px solid #FF6136;
                border-left: 18px solid #FF6136;
            }

            @media only screen and (max-width: 500px) {
                .button {
                    width: 100% !important;
                    text-align: center !important;
                }
            }
            /* Attribute list ------------------------------ */

            .attributes {
                margin: 0 0 21px;
            }

            .attributes_content {
                background-color: #F4F4F7;
                padding: 16px;
            }

            .attributes_item {
                padding: 0;
            }
            /* Related Items ------------------------------ */

            .related {
                width: 100%;
                margin: 0;
                padding: 25px 0 0 0;
                -premailer-width: 100%;
                -premailer-cellpadding: 0;
                -premailer-cellspacing: 0;
            }

            .related_item {
                padding: 10px 0;
                color: #CBCCCF;
                font-size: 15px;
                line-height: 18px;
            }

            .related_item-title {
                display: block;
                margin: .5em 0 0;
            }

            .related_item-thumb {
                display: block;
                padding-bottom: 10px;
            }

            .related_heading {
                border-top: 1px solid #CBCCCF;
                text-align: center;
                padding: 25px 0 10px;
            }
            /* Discount Code ------------------------------ */

            .discount {
                width: 100%;
                margin: 0;
                padding: 24px;
                -premailer-width: 100%;
                -premailer-cellpadding: 0;
                -premailer-cellspacing: 0;
                background-color: #F4F4F7;
                border: 2px dashed #CBCCCF;
            }

            .discount_heading {
                text-align: center;
            }

            .discount_body {
                text-align: center;
                font-size: 15px;
            }
            /* Social Icons ------------------------------ */

            .social {
                width: auto;
            }

                .social td {
                    padding: 0;
                    width: auto;
                }

            .social_icon {
                height: 20px;
                margin: 0 8px 10px 8px;
                padding: 0;
            }
            /* Data table ------------------------------ */

            .purchase {
                width: 100%;
                margin: 0;
                padding: 35px 0;
                -premailer-width: 100%;
                -premailer-cellpadding: 0;
                -premailer-cellspacing: 0;
            }

            .purchase_content {
                width: 100%;
                margin: 0;
                padding: 25px 0 0 0;
                -premailer-width: 100%;
                -premailer-cellpadding: 0;
                -premailer-cellspacing: 0;
            }

            .purchase_item {
                padding: 10px 0;
                color: #51545E;
                font-size: 15px;
                line-height: 18px;
            }

            .purchase_heading {
                padding-bottom: 8px;
                border-bottom: 1px solid #EAEAEC;
            }

                .purchase_heading p {
                    margin: 0;
                    color: #85878E;
                    font-size: 12px;
                }

            .purchase_footer {
                padding-top: 15px;
                border-top: 1px solid #EAEAEC;
            }

            .purchase_total {
                margin: 0;
                text-align: right;
                font-weight: bold;
                color: #333333;
            }

            .purchase_total--label {
                padding: 0 15px 0 0;
            }

            body {
                background-color: #F4F4F7;
                color: #51545E;
            }

            p {
                color: #51545E;
            }

                p.sub {
                    color: #6B6E76;
                }

            .email-wrapper {
                width: 100%;
                margin: 0;
                padding: 0;
                -premailer-width: 100%;
                -premailer-cellpadding: 0;
                -premailer-cellspacing: 0;
                background-color: #F4F4F7;
            }

            .email-content {
                width: 100%;
                margin: 0;
                padding: 0;
                -premailer-width: 100%;
                -premailer-cellpadding: 0;
                -premailer-cellspacing: 0;
            }
            /* Masthead ----------------------- */

            .email-masthead {
                padding: 25px 0;
                text-align: center;
            }

            .email-masthead_logo {
                width: 94px;
            }

            .email-masthead_name {
                font-size: 16px;
                font-weight: bold;
                color: #A8AAAF;
                text-decoration: none;
                text-shadow: 0 1px 0 white;
            }
            /* Body ------------------------------ */

            .email-body {
                width: 100%;
                margin: 0;
                padding: 0;
                -premailer-width: 100%;
                -premailer-cellpadding: 0;
                -premailer-cellspacing: 0;
                background-color: #FFFFFF;
            }

            .email-body_inner {
                width: 570px;
                margin: 0 auto;
                padding: 0;
                -premailer-width: 570px;
                -premailer-cellpadding: 0;
                -premailer-cellspacing: 0;
                background-color: #FFFFFF;
            }

            .email-footer {
                width: 570px;
                margin: 0 auto;
                padding: 0;
                -premailer-width: 570px;
                -premailer-cellpadding: 0;
                -premailer-cellspacing: 0;
                text-align: center;
            }

                .email-footer p {
                    color: #6B6E76;
                }

            .body-action {
                width: 100%;
                margin: 30px auto;
                padding: 0;
                -premailer-width: 100%;
                -premailer-cellpadding: 0;
                -premailer-cellspacing: 0;
                text-align: center;
            }

            .body-sub {
                margin-top: 25px;
                padding-top: 25px;
                border-top: 1px solid #EAEAEC;
            }

            .content-cell {
                padding: 35px;
            }
            /*Media Queries ------------------------------ */

            @media only screen and (max-width: 600px) {
                .email-body_inner,
                .email-footer {
                    width: 100% !important;
                }
            }

            @media (prefers-color-scheme: dark) {
                body,
                .email-body,
                .email-body_inner,
                .email-content,
                .email-wrapper,
                .email-masthead,
                .email-footer {
                    background-color: #333333 !important;
                    color: #FFF !important;
                }

                p,
                ul,
                ol,
                blockquote,
                h1,
                h2,
                h3 {
                    color: #FFF !important;
                }

                .attributes_content,
                .discount {
                    background-color: #222 !important;
                }

                .email-masthead_name {
                    text-shadow: none !important;
                }
            }
        </style>
        <!--[if mso]>
          <style type="text/css">
            .f-fallback  {
              font-family: Arial, sans-serif;
            }
          </style>
        <![endif]-->
    </head>
    <body>
        <span class="preheader">Thanks for trying out [Product Name]. We�ve pulled together some information and resources to help you get started.</span>
        <table class="email-wrapper" width="100%" cellpadding="0" cellspacing="0" role="presentation">
            <tr>
                <td align="center">
                    <table class="email-content" width="100%" cellpadding="0" cellspacing="0" role="presentation">
                        <tr>
                            <td class="email-masthead">
                                <a href="${ActionButton}" class="f-fallback email-masthead_name">
                                    ${Logo}
                                </a>
                            </td>
                        </tr>
                        <!-- Email Body -->
                        <tr>
                            <td class="email-body" width="100%" cellpadding="0" cellspacing="0">
                                <table class="email-body_inner"
                                       align="center" width="570"
                                       cellpadding="0" cellspacing="0" role="presentation">
                                    <!-- Body content -->
                                    <tr>
                                        <td class="content-cell">
                                            <div class="f-fallback">
                                                <h1>${Heading}</h1>
                                                <p>${Message}</p>
                                                <!-- Action -->
                                                <table class="body-action"
                                                       align="center" width="100%"
                                                       cellpadding="0" cellspacing="0"
                                                       role="presentation">
                                                    <tr>
                                                        <td align="center">
                                                        ${ShowButton}
                                                        </td>
                                                    </tr>
                                                </table>
                                                ${ImagesBlock}
                                               ${PreSelectCode}

                                               
                                                <table class="body-sub" role="presentation">
                                                    <tr>
                                                        <td>
                                                            <p class="f-fallback sub">If you are having trouble with the button above, copy and paste the URL below into your web browser.</p>
                                                            <p class="f-fallback sub">
                                                            ${ActionButton}
                                                            </p>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table class="email-footer" align="center" width="570" cellpadding="0" cellspacing="0" role="presentation">
                                    <tr>
                                        <td class="content-cell" align="center">

                                            <p class="f-fallback sub align-center">
                                                ${ContractorName}
                                                <br />${ContractorAddress}
                                                <br />${ContractorPhone}
                                            </p>
<p class="f-fallback sub align-center">
If you do not wish to receive further communications like this, please <a href="${UnsubscribeLink}">unsubscribe</a>.
</p>

<p class="f-fallback sub align-center">&copy; 2022 NotificationBridge.com. All rights reserved.</p>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
        </table>
    </body>
</html>
`);
}
