import React, { useState, useEffect } from 'react';
import {
    Stack,
    Box,
    Grid,
    CardHeader,
    IconButton,
    Typography,
    Button,
    Card,
    CardContent,
    Avatar, 
    Stepper,
    Step,
    StepLabel,
    StepContent,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ProgressShowBox from '../Setup/ProgressShowBox';
import EditIcon from '@mui/icons-material/Edit';
import { red } from '@mui/material/colors';
import {
    removeEmptyStringElements,    
    UpdateData,
    FindCategoryIcon
} from '../utils/CommonGraphql';


const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));


const isNotEmptyObject = (value) => {
    return value && value.constructor === Object && Object.keys(value).length > 0;
}



const Account = (props) => {
    const classes = useStyles();

    const [activeStep, setActiveStep] = useState(-1);
    const initialState = {
        StepChecked: [],
        ServiceSteps: [],
        CurrentStep: 0,
    }
    const [state, setState] = useState(initialState);
  //  const [FormGroupSelectFound, SetFormGroupSelectFound] = useState();

    useEffect(() => {
        console.log("ProgressStepper.props ", props);

        let SREvent = props.SREvent;
        let ServiceSteps = SREvent.ProgressList;
        let CurrentStep = SREvent.CurrentProgressStep;
        let RequestorCompanyId = "";
        let RequestorCompanyName = "";

        if (SREvent.posterId && SREvent.posterId.CompanyId) {
            RequestorCompanyId = SREvent.posterId.CompanyId;
            RequestorCompanyName = SREvent.posterId.CompanyName;
        }

        console.log("ProgressStepper.ServiceSteps ", ServiceSteps);

        setState(prevState => ({
            ...prevState,
            SREvent: SREvent,
            ServiceSteps: ServiceSteps,
            CurrentStep: CurrentStep,
            RequestorCompanyId: RequestorCompanyId,
            RequestorCompanyName: RequestorCompanyName,
        }));

    }, [props.SREvent])

    //useEffect(() => {
        
    //    if (props.CurrentStep && props.CurrentStep > 0) {
    //        setActiveStep(props.CurrentStep);
    //    }

    //}, [props.CurrentStep])



    const SaveImage = (name, e) => {
        setState(prevState => ({
            ...prevState,
            [name]: e
        }));
    };

    const ReturnBackData = form => {
        //console.log('formItemAddForm1', form);
        //let projects = form.concat(state.NewService); //[...state.NewService, ...form]
        console.log('formItemAddForm1', form);

        setState(prevState => ({
            ...prevState,
            TotalFormData: form
        }));
    }

    const EndforNow = (SREvent) => {
        setActiveStep(-1);

        //updating Progress seperate database
        UpdateDatabase(SREvent)

        if (props.UpdateEvent) {
            props.UpdateEvent(SREvent);
        }
    }

    const UpdateDatabase = (SREvent) => {

        var ValueToUpdate = {
            //pk1: SREvent.ServiceRequest.pk1,
            //sk1: SREvent.ServiceRequest.sk1,

            pk1: `Post::${SREvent.PostId}`,
            sk1: `Post::Progress::${SREvent.ServiceRequest.sk1}`,

          //  gsi1Pk2: each.key,
            gsi1Sk2: `Post::Progress::${SREvent.Progress}`,
          //  gsi2Pk2: event.posterId.CompanyId,
            gsi2Sk2: `Post::Progress::${SREvent.Progress}`,
           // dataJSON: JSON.stringify(removeEmptyStringElements(SREvent)),
        };

        console.log("ProgressStepper.ValueToUpdate", ValueToUpdate)

        UpdateData(ValueToUpdate)
            .then((up) => {
                // dispatch({ type: 'TRIGGER_UPDATE', payload: true });
                console.log("ProgressStepper.UpdateDatabase", up)
            })
            .catch((err) => console.log("UpdateDatabase.failed", err));
    }

    const handleNext = (required, key, Next) => {

        let Nextstepgo = true;

        // testing without checking value cheked.
        //let Nextstepgo = false;
        //if (required) {
        //    if (state.StepChecked[key] && state.StepChecked[key] === true) {
        //        Nextstepgo = true;
        //    } else {
        //        Nextstepgo = false;
        //    }
        //} else {
        //    Nextstepgo = true
        //}

        console.log("ProgressStepper.Nextstepgo", Nextstepgo);
        if (Nextstepgo) {

            let ServiceSteps = state.ServiceSteps;
            let item = ServiceSteps[key];
            let labelTop = item.label;

            //if (item.optionsSelected !== "") {
            //  labelTop += ` (${item.optionlabel}: ${item.optionsSelected})`;
            //}

            if (state.StepChecked[key] === true) {
                if (item.Notes !== "") {
                    labelTop += ` (Notes: ${item.Notes})`;
                }

                if (item.date !== "") {
                    labelTop += ` (Updated: ${item.date})`;
                }
            }

            item.labelTop = labelTop;

            ServiceSteps[key] = item;

            let totalChecked = ServiceSteps.filter(x => x.stepcomplete === true);
            let totalcount = ServiceSteps.filter(x => x.include === true);
          //  console.log("ProgressStepper.Nextstepgo", Nextstepgo);
                //ServiceSteps.length;
            let percent = Math.round((Number(totalChecked.length) / Number(totalcount.length)) * 100)
            console.log("ProgressStepper.SREvent.percent", percent, totalcount, totalChecked.length);
            let NewStep = activeStep + 1;

            let SREvent = state.SREvent;
            SREvent.CurrentProgressStep = NewStep;
            SREvent.ProgressList = ServiceSteps;
            SREvent.Progress = percent;

            console.log("ProgressStepper.SREvent", SREvent);

            if (props.UpdateEvent && totalChecked.length === totalcount.length) {
                props.UpdateEvent(SREvent);
            }

            setState({
                ...state,
                ServiceSteps: ServiceSteps,
                CurrentStep: NewStep,
                SREvent: SREvent,
            });

            if (Next === "Next") {
                setActiveStep(NewStep);
            }

            if (Next === "End") {
                EndforNow(SREvent);
            }
            

        } else {
            setState(prevState => ({
                ...prevState,
                AlarmMessage: "Please select items before proceeding",
                OpenAlert: true
            }));
        }

    };

    const handleBack = () => {

        let NewStep = activeStep - 1;


        let SREvent = state.SREvent;
        SREvent.CurrentProgressStep = NewStep;



        setState({
            ...state,
            //  ServiceSteps: ServiceSteps,
            CurrentStep: NewStep,
            SREvent: SREvent,
        });

        setActiveStep(NewStep);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const UpdateStep = (item, index) => {
        console.log('ProgressStepper.UpdateStep', item, index);
        let ServiceSteps = state.ServiceSteps;
        ServiceSteps[index] = item;
        setState({
            ...state,
            ServiceSteps: ServiceSteps,
        });
    }

    const StepCheckedValue = (item, index) => {
        let StepChecked = state.StepChecked
        StepChecked[index] = item;
        setState({
            ...state,
            StepChecked: StepChecked,
        });
    };

    const UpdateProgress = () => {
        setActiveStep(state.CurrentStep);
        //setState(prevState => ({
        //  ...prevState,
        //  DetailComponent: "ProgressStepper",
        //  DrawerTitle: "Update Progress"
        //}))

        //SetDeawerOpen(true)
    }


    console.log('ProgressStepper.state', state);
    return (
        <>
       
                <Card key={"crwwsd1"}>
                    <CardHeader
                        title="Work Progress"
                        //subheader="Information"
                        action={
                            <IconButton aria-label="settings"
                                onClick={() => handleReset()}
                                size="large">
                                <EditIcon />
                            </IconButton>
                        }
                        avatar={
                            <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe" >
                                {FindCategoryIcon("Progress")}
                            </Avatar>
                        }
                    />

                    <CardContent>
                        
                    

                <Stepper activeStep={activeStep} orientation="vertical">
                    {state.ServiceSteps && state.ServiceSteps.map((eachstep, indd) => {
                        console.log('ProgressStepper.ServiceSteps', indd, eachstep);
                        return (

                            <Step key={indd}>
                                <StepLabel>{eachstep.labelTop}</StepLabel>
                                <StepContent>
                                    <Grid container spacing={2}>

                                        <Grid item xs={12}>
                                            <Typography>
                                                {eachstep.message}
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={12}>


                                            <ProgressShowBox
                                                Step={eachstep}
                                                disabled={false}
                                                NewStep={(e) => UpdateStep(e, indd)}
                                                StepChecked={(e) => StepCheckedValue(e, indd)}
                                                RequestorCompanyId={state.RequestorCompanyId}
                                                RequestorCompanyName={state.RequestorCompanyName}
                                                AddMessage={() => props.AddMessage()}
                                            />

                                        </Grid>



                                        <Grid item xs={12}>
                                            <Stack
                                                direction={{ xs: 'column' }}
                                                spacing={2}
                                            >
                                                {indd > 0 && (
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={handleBack}
                                                        className={classes.button}
                                                    >
                                                        Back
                                                    </Button>
                                                )}

                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => handleNext(eachstep.required, indd, "Next")}
                                                    className={classes.button}
                                                >
                                                    {'Save & Next Step'}
                                                </Button>
                                                
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => handleNext(eachstep.required, indd, "End")}
                                                    className={classes.button}
                                                >
                                                    {'Done for now'}
                                                </Button>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </StepContent>
                            </Step>

                        )

                    })}
                    </Stepper>

                    <Box >
                        <Button onClick={handleReset} className={classes.button}>
                            Reset
                        </Button>
                    </Box>

                    </CardContent>
                </Card>


        
           

</>
    );
};

export default Account;
