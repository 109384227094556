import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  
} from '@mui/material';
import DynamicForms from '../Forms/FormShow';
import { NewPageId } from '../utils/CommonGraphql';

import AutoComplateBox from '../components/AutoCompleteBox'

const FormId = NewPageId("ItemShowDetails");
//import Snackbar from '@mui/material/Snackbar';
//import MuiAlert from '@mui/lab/Alert';
//import { useSelector } from 'react-redux';
//import { FormSchema, FormGroups } from '../Forms/FormList';
//import { PrimaryKeyDataGet } from '../utils/CommonGraphql';
//import ComboForm from '../Forms/ComboForm';
////import AutocompleteBox from '../components/AutoComplete';

//function Alert(props) {
//  return <MuiAlert elevation={6} variant="filled" {...props} />;
//}

//const useStyles = makeStyles(() => ({
//  root: {}
//}));

const ProfileDetails = ({ className, ...rest }) => {
  //const classes = useStyles();
  //const [savedetails, Setsavedetails] = useState(false);
  //const FormPrefData = useSelector(state => state.Pref)

  //console.log("ItemShowDetail", rest);

  let initialState = {
    FormJSON: [],
    FormValueArray: [],
    FormJSONBack: []
  };

  const [state, setState] = useState(initialState);

  useEffect(() => {
    console.log("ItemShowDetail", rest);
    if (rest.Data) {
      setState(prevState => ({
        ...prevState,
        FormValueArray: rest.Data.FormData
      }));
    }
  }, [])

  //const getdata = async (Data) => {
  //  try {
  //    let DataList = [];
  //    let 
  //    const DataBack = await PrimaryKeyDataGet(Data.pk1, Data.sk1);
  //    const NewData = await DataBack;
  //    console.log("DataBack", DataBack)
  //    if (NewData) {
  //      DataList = ObjData(NewData);
  //      setRaw(DataList);

  //      setState(prevState => ({
  //        ...prevState,
  //        FormValueArray: projects
  //      }));

  //    }
  //    console.log(`DataList`, DataList);

  //  } catch (err) {
  //    console.log(err)
  //  }
  //}

  //const FormArray = (Category) => {

  //  try {

  //    //formgroup contains forms that will be presented together
  //    let FormGroupSelect = FormGroups(Category);
  //    let FormData = [];
  //    let FormJSON = [];
  //    console.log("FormGroupSelect Cat", Category, FormGroupSelect)
  //    FormGroupSelect.map((each, index) => {

  //      if (each.FormType === "AutoComplate") {
  //        FormData = [];
  //      } else {
  //        FormData = FormSchema.filter(x => x.Category === each.Category);
  //      }

  //      console.log("FormData Cat", each, FormData);

  //      FormJSON.push({
  //        FormType: each.FormType,
  //        key: each.FormType + index,
  //        FormName: each.FormName,
  //        Category: each.Category,
  //        FormData: FormData,
  //        ValueAdded: false
  //      })

  //    })

  //    console.log("FormJSONCat", FormJSON);
  //    setState(prevState => ({
  //      ...prevState,
  //      FormJSON: FormJSON,
  //      FormJSONBack: FormJSON
  //    }));

  //  } catch (err) { console.log("FormArray err", err) }
  //};

  //const SaveDetails = () => {

  //  let ValueAdded = true;
  //  let AlarmMessage = "";
  //  state.FormJSONBack.map(each => {
  //    if (!each.ValueAdded) {
  //      ValueAdded = false;
  //      AlarmMessage += `* Please complete ${each.FormName} `;
  //      console.log("AlarmMessage", AlarmMessage)
  //    }
  //  })
  //  Setsavedetails(ValueAdded);
  //  console.log("state.FormJSONBack", ValueAdded, state.FormJSONBack)
  //  if (ValueAdded) {

  //    rest.ReturnBackData(state.FormJSONBack);
  //    rest.SaveData(state.FormJSONBack);

  //  } else {
  //    setState(prevState => ({
  //      ...prevState,
  //      AlarmMessage: AlarmMessage,
  //      OpenAlert: true
  //    }));
  //  }

  //}

  //const handleClose = (event, reason) => {
  //  setState(prevState => ({
  //    ...prevState,
  //    AlarmMessage: "",
  //    OpenAlert: false
  //  }));

  //};

  //const ReturnBackData = (e) => {
  //  console.log("ReturnBackData", e)
  //  setState(prevState => ({
  //    ...prevState,
  //    FormJSONBack: e
  //  }));
  //};

  const OnFormSubmit = (model, key) => {
    try {

      console.log("model", key, model);

      let projects = state.FormValueArray;
      projects[key] = model;
      projects[key].ValueAdded = true;

      setState(prevState => ({
        ...prevState,
        FormValueArray: projects
      }));
      console.log("ReturnFormData", projects)
      // props to send the data back
      if (rest.ReturnBackData) {
        rest.ReturnBackData(projects);
      }

    } catch (err) { }
  };



  return (

    <div key={FormId + rest.key} >
      <Card>
        <CardHeader
          //subheader="The information can be edited"
          title={rest.title}
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={2}
          >
            {rest.Data && rest.Data.FormData && rest.Data.FormData.length > 0 && rest.Data.FormData.map((each, index) => (
              <Grid
                item
                xs={12}
                key={index}
              >
                {each.FormType === "AutoComplate" ? (

                  <AutoComplateBox
                    key={index}
                    id={index}
                    SaveDetails={rest.SaveDetails}
                    //FormCompleteVerify={e => console.log("FormCompleteVerify", e)}
                    Category={each.Category}
                    FormType={each.FormType}
                    title={each.FormName}
                    ShowEditButton={false}
                    ReturnBackData={e => rest.ReturnBackData(e)}
                    PreviewEdit={false}
                    EditItem={e => console.log("EditItem", e)}
                    //FormData={each.FormData}
                    onSubmit={(model) => OnFormSubmit(model, index)}
                  />

                ) : (
                    <DynamicForms
                      key={index}
                      id={index}
                      //SaveDetails={rest.SaveDetails}
                      Category={each.Category}
                      FormType={each.FormType}
                      //FormCompleteVerify={e => console.log("FormCompleteVerify", e)}
                      title={each.FormName}
                      ShowEditButton={true}
                      UpdateFormData={e => console.log("UpdateFormData", e)}
                      PreviewEdit={false}
                      EditItem={e => console.log("EditItem", e)}
                      FormData={each.FormData}
                      onSubmit={(model) => OnFormSubmit(model, index)}
                    />
                  )}
              </Grid>
            ))}
          </Grid>
        </CardContent>
        <Divider />
        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
        >
          <Button
            color="primary"
            variant="contained"
          // onClick={() => SaveDetails()}
          >
            Save details
          </Button>
        </Box>
      </Card>
    </div>

  );
};

ProfileDetails.propTypes = {
  className: PropTypes.string
};

export default ProfileDetails;
