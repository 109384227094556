import { PrimaryKeyDataGet } from '../utils/CommonGraphql';
import { useSelector } from 'react-redux';
import { DocumentType } from '../utils/Documents';

var store = require('store');

const GetDocumentType = () => {
    let DocType = []
    DocumentType.map((each, indd) => {
        DocType.push({ key: each.key, label: each.label, value: each.key })
    })

    console.log("DocumentType", DocumentType);
    console.log("DocumentType.DocType", DocType)

    if (DocType && DocType.length > 0) {
        return DocType;
    }
    
}


export const CommonFormUpdated = "2021-08-2929";
export const FormPrefArray = [
    "Form::Person",
    "Form::Equipment",
    "Form::Location",
    "Form::Building",
    "Form::Room",
    "Form::Team",
    "Form::Company",
    "Form::Address",
];

// the basic field in each form type
export const FormSchema = [

    //Contractor signup admin
    {
        Order: 1,
        key: 1,
        Category: "ContractorSignupAdmin",
        extra: "field1",
        required: true,
        Searchable: true,
        options: [],
        label: "Name",
        VariableName: "name",
        VariableInput: "",
        Editable: false,
        TableColumn: true,
        message: "",
        type: "textline",
        ListInfo: true
    },
    {
        Order: 1,
        options: [],
        label: "Email",
        VariableName: "email",
        Searchable: true,
        VariableInput: "",
        Editable: false,
        TableColumn: true,
        extra: "field2",
        message: "",
        type: "email",
        key: 2,
        Category: "ContractorSignupAdmin",
        required: true,
        ListInfo: true
    },
    {
        Order: 3,
        options: [],
        label: "Position",
        VariableName: "position",
        Searchable: true,
        Editable: true,
        TableColumn: false,
        extra: "",
        VariableInput: "",
        message: "",
        type: "textline",
        key: 3,
        Category: "ContractorSignupAdmin",
        required: false,
        ListInfo: true
    },
    {
        Order: 3,
        options: [],
        label: "Work Phone",
        VariableName: "phone",
        Searchable: true,
        Editable: true,
        TableColumn: false,
        extra: "",
        VariableInput: "",
        message: "",
        type: "phone",
        key: 3,
        Category: "ContractorSignupAdmin",
        ListInfo: true,
        required: false
    },
    {
        Order: 3,
        options: [],
        label: "Mobile/SMS",
        VariableName: "mobile",
        Editable: true,
        TableColumn: false,
        extra: "",
        VariableInput: "",
        message: "",
        type: "phone",
        key: 3,
        Category: "ContractorSignupAdmin",
        ListInfo: true,
        required: true
    },

    //{
    //    Order: 2,
    //    options: [],
    //    label: "Allow SMS Service",
    //    VariableName: "AllowSMSService",
    //    defaultValue: true,
    //    Category: "Person",
    //    VariableInput: true,
    //    message: "",
    //    type: "AllowSMS",
    //    key: "jvUSKJYef",
    //    required: false,
    //    Searchable: true,
    //    Editable: true,
    //    TableColumn: false,
    //    extra: "",
    //},

    //Person
    {
        Order: 1,
        key: 1,
        Category: "Person",
        extra: "field1",
        required: true,
        Searchable: true,
        options: [],
        label: "Name",
        VariableName: "name",
        VariableInput: "",
        Editable: false,
        TableColumn: true,
        ListInfo: true,
        message: "",
        type: "textline",
    },
    {
        Order: 1,
        options: [],
        label: "Email",
        VariableName: "email",
        Searchable: true,
        VariableInput: "",
        Editable: false,
        TableColumn: true,
        extra: "field2",
        message: "",
        type: "email",
        key: 2,
        Category: "Person",
        ListInfo: true,
        required: true,
    },
    //{
    //    Order: 3,
    //    options: [],
    //    label: "Position",
    //    VariableName: "position",
    //    Searchable: true,
    //    Editable: true,
    //    TableColumn: false,
    //    extra: "",
    //    VariableInput: "",
    //    message: "",
    //    type: "textline",
    //    key: 3,
    //    Category: "Person",
    //    required: false
    //},
    {
        Order: 1,
        options: [],
        label: "Time Zone",
        VariableName: "TimeZone",
        VariableInput: "",
        Editable: false,
        TableColumn: true,
        extra: "",
        message: "",
        type: "TimeZone",
        key: 200,
        Category: "Person",
        required: true,
        Searchable: true,

    },
    {
        Order: 3,
        options: [],
        label: "Mobile/SMS",
        VariableName: "mobile",
        Editable: true,
        TableColumn: false,
        extra: "",
        VariableInput: "",
        message: "",
        type: "phone",
        key: 3,
        Category: "Person",
        ListInfo: true,
        required: true
    },
    {
        Order: 3,
        options: [],
        label: "Work Phone",
        VariableName: "phone",
        Searchable: true,
        Editable: true,
        TableColumn: false,
        extra: "",
        VariableInput: "",
        message: "",
        type: "phone",
        key: 3,
        Category: "Person",
        ListInfo: true,
        required: false
    },

    {
        Order: 2,
        options: [],
        label: "Allow SMS Service",
        VariableName: "AllowSMSService",
        defaultValue: true,
        Category: "Person",
        VariableInput: true,
        message: "",
        type: "AllowSMS",
        key: "jvUSKJYef",
        required: false,
        Searchable: true,
        Editable: true,
        TableColumn: false,
        extra: "",
    },

    {
        Order: 2,
        options: [],
        label: "Authority Level",
        VariableName: "AuthorityLevel",
        defaultValue: true,
        Category: "Person",
        VariableInput: true,
        message: "",
        type: "Priviliges",
        key: "jvUSKJYssddsef",
        required: false,
        Searchable: true,
        Editable: true,
        TableColumn: false,
        extra: "",
    },

    {
        Order: 2,
        options: [],
        label: "Status",
        VariableName: "status",
        defaultValue: true,
        Category: "Person",
        VariableInput: true,
        message: "",
        type: "switch",
        key: "jvUSKJYssddsef",
        required: false,
        Searchable: true,
        Editable: true,
        TableColumn: false,
        extra: "",
    },

    
    //{
    //  Order: 1,
    //  options: [],
    //  VariableName: "Team",
    //  VariableInput: "",
    //  Editable: false,
    //  TableColumn: true,
    //  extra: "",
    //  message: "",
    //  type: "select",
    //  key: 1001,
    //  Category: "Person",
    //  required: false,
    //  label: "Team",
    //  DataSourse: "Team",
    //  defaultValue: [""],
    //  options: []
    //},

    //AddSMS

    {
        Order: 1,
        key: 1,
        Category: "AddSMS",
        extra: "field1",
        required: true,
        Searchable: true,
        options: [],
        label: "Short Title",
        VariableName: "name",
        VariableInput: "",
        Editable: false,
        TableColumn: true,
        message: "",
        type: "textline",
    },
    {
        Order: 3,
        options: [],
        label: "Mobile/SMS",
        VariableName: "mobile",
        extra: "",
        Editable: false,
        TableColumn: false,
        extra: "",
        VariableInput: "",
        message: "",
        type: "phone",
        key: 3,
        Category: "AddSMS",
        required: true
    },

    {
        Order: 1,
        options: [],
        VariableName: "type",
        VariableInput: "",
        Editable: false,
        TableColumn: true,
        extra: "field2",
        message: "",
        type: "select",
        key: 1001,
        Category: "AddSMS",
        Searchable: true,
        required: true,
        label: "SMS Function / Type",
        DataSourse: "options",
        defaultValue: ["todo"],
        options: [
            { key: "todo", label: "Add to-do list", value: "todo" },
            { key: "schedule", label: "Schedule service", value: "schedule" },
            { key: "emergency", label: "Emergency/Urgent Service", value: "emergency" },
            { key: "quote", label: "Request for Quote/Proposal", value: "quote" },
            { key: "dedicated", label: "Dedicated SMS", value: "dedicated" }
        ]
    },

    {
        Order: 1,
        options: [],
        VariableName: "public",
        VariableInput: "",
        Editable: false,
        TableColumn: true,
        extra: "",
        message: "",
        type: "select",
        key: 1001,
        Category: "AddSMS",
        Searchable: true,
        required: true,
        label: "Public / Dedicated",
        DataSourse: "options",
        defaultValue: ["public"],
        options: [
            { key: "public", label: "Any Customer", value: "public" },
            { key: "dedicated", label: "Dedicated", value: "dedicated" },
        ]
    },
   
    //{
    //    Order: 3,
    //    options: [],
    //    label: "Service Phone Number",
    //    VariableName: "phone",
    //    extra: "",
    //    Editable: false,
    //    TableColumn: false,
    //    extra: "",
    //    VariableInput: "",
    //    message: "",
    //    type: "phone",
    //    key: 3,
    //    Category: "AddSMS",
    //    required: true
    //},
    //{
    //    Order: 1,
    //    options: [],
    //    label: "Address",
    //    VariableName: "Address",
    //    VariableInput: "",
    //    Editable: false,
    //    TableColumn: true,
    //    extra: "",
    //    message: "",
    //    type: "GoogleAddressAutoComplete",
    //    key: 200,
    //    Category: "AddSMS",
    //    ListInfo: true,
    //    required: true,
    //    Searchable: true,
    //},

    //{
    //    Order: 1,
    //    options: [],
    //    label: "Description",
    //    VariableName: "Description",
    //    VariableInput: "",
    //    Editable: true,
    //    TableColumn: false,
    //    message: "",
    //    type: "textbox",
    //    key: 102221,
    //    Category: "AddSMS",
    //    required: false,
    //},

    //"EmailTrigger"
    {
        Order: 3,
        options: [],
        label: "Trigger Email",
        VariableName: "email",
        extra: "field2",
        Editable: false,
        TableColumn: false,
        extra: "",
        VariableInput: "",
        message: "",
        type: "email",
        key: 3,
        Category: "EmailTrigger",
        required: true
    },
    {
        Order: 1,
        key: 1,
        Category: "EmailTrigger",
        extra: "field1",
        required: true,
        Searchable: true,
        options: [],
        label: "Short Title",
        VariableName: "name",
        VariableInput: "",
        Editable: false,
        TableColumn: true,
        message: "",
        type: "textline",
    },


    {
        Order: 1,
        options: [],
        VariableName: "type",
        VariableInput: "",
        Editable: false,
        TableColumn: true,
        extra: "",
        message: "",
        type: "select",
        key: 1001,
        Category: "EmailTrigger",
        Searchable: true,
        required: true,
        label: "SMS Function / Type",
        DataSourse: "options",
        defaultValue: ["todo"],
        options: [
            { key: "DDC", label: "Control System Alarm", value: "DDC" },
            { key: "cmms", label: "CMMS system email notification", value: "cmms" },
            { key: "custom", label: "Custom email trigger", value: "custom" },
        ]
    },
    {
        Order: 1,
        options: [],
        label: "Description",
        VariableName: "Description",
        VariableInput: "",
        Editable: true,
        TableColumn: false,
        message: "",
        type: "textbox",
        key: 102221,
        Category: "EmailTrigger",
        required: false,
    },

    //RequestNewSMS
    {
        Order: 3,
        options: [],
        label: "Add New SMS Number",
        VariableName: "areacode",
        extra: "field2",
        Editable: false,
        TableColumn: false,
        extra: "",
        VariableInput: "",
        message: "The cost for the new number setup and programming is $199.  A seperate email will be sent with online payment link. Our team will contact you once the new number is setup and walk you through all the options and system configuration",
        type: "message",
        key: 3,
        Category: "RequestNewSMS",
        required: true
    },
    {
        Order: 3,
        options: [],
        label: "Prefered Area Code",
        VariableName: "areacode",
        extra: "",
        Editable: false,
        TableColumn: false,
        extra: "",
        VariableInput: "",
        message: "",
        type: "textline",
        key: 3,
        Category: "RequestNewSMS",
        required: true
    },
    {
        Order: 1,
        key: 1,
        Category: "RequestNewSMS",
        extra: "field1",
        required: true,
        Searchable: true,
        options: [],
        label: "Short Title",
        VariableName: "name",
        VariableInput: "",
        Editable: false,
        TableColumn: true,
        message: "",
        type: "textline",
    },

    {
        Order: 1,
        key: 1,
        Category: "RequestNewSMS",
        extra: "field2",
        required: true,
        Searchable: true,
        options: [],
        label: "Your Email",
        VariableName: "email",
        VariableInput: "",
        Editable: false,
        TableColumn: true,
        message: "",
        type: "email",
    },

    {
        Order: 1,
        options: [],
        VariableName: "type",
        VariableInput: "",
        Editable: false,
        TableColumn: true,
        extra: "",
        message: "",
        type: "select",
        key: 1001,
        Category: "RequestNewSMS",
        Searchable: true,
        required: true,
        label: "SMS Function / Type",
        DataSourse: "options",
        defaultValue: ["todo"],
        options: [
            { key: "todo", label: "Add to-do list", value: "todo" },
            { key: "schedule", label: "Schedule service", value: "schedule" },
            { key: "emergency", label: "Emergency/Urgent Service", value: "emergency" },
            { key: "quote", label: "Request for Quote/Proposal", value: "quote" },
            { key: "dedicated", label: "Dedicated SMS", value: "dedicated" }
        ]
    },
    //{
    //    Order: 1,
    //    options: [],
    //    label: "Description",
    //    VariableName: "Description",
    //    VariableInput: "",
    //    Editable: true,
    //    TableColumn: false,
    //    message: "",
    //    type: "textbox",
    //    key: 102221,
    //    Category: "RequestNewSMS",
    //    required: false,
    //},

    {
        Order: 3,
        options: [],
        label: "Service Phone Number",
        VariableName: "phone",
        extra: "",
        Editable: false,
        TableColumn: false,
        extra: "",
        VariableInput: "",
        message: "",
        type: "phone",
        key: 3,
        Category: "RequestNewSMS",
        required: true
    },
    {
        Order: 1,
        options: [],
        label: "Branch/Office Address",
        VariableName: "Address",
        VariableInput: "",
        Editable: false,
        TableColumn: true,
        extra: "",
        message: "",
        type: "GoogleAddressAutoComplete",
        key: 200,
        Category: "RequestNewSMS",
        ListInfo: true,
        required: true,
        Searchable: true,
    },

    //ContractorSignupSMS
    {
        Order: 3,
        options: [],
        label: "1st SMS Area Code",
        VariableName: "areacode1",
        extra: "field2",
        Editable: false,
        TableColumn: false,
        extra: "",
        VariableInput: "",
        message: "",
        type: "textline",
        key: 3,
        Category: "ContractorSignupSMS",
        required: true
    },
    {
        Order: 1,
        options: [],
        VariableName: "SMSFunction1",
        VariableInput: "",
        Editable: false,
        TableColumn: true,
        extra: "",
        message: "",
        type: "select",
        key: 1001,
        Category: "ContractorSignupSMS",
        Searchable: true,
        required: false,
        label: "1st SMS Function",
        DataSourse: "options",
        defaultValue: ["emergency"],
        options: [
            { key: "todo", label: "Add to-do list", value: "todo" },
            { key: "schedule", label: "Schedule service", value: "schedule" },
            { key: "emergency", label: "Emergency/Urgent Service", value: "emergency" },
            { key: "quote", label: "Request for Quote/Proposal", value: "quote" },
            { key: "dedicated", label: "Dedicated SMS", value: "dedicated" }
        ]
    },
    {
        Order: 3,
        options: [],
        label: "2nd SMS Area Code",
        VariableName: "areacode2",
        extra: "field2",
        Editable: false,
        TableColumn: false,
        extra: "",
        VariableInput: "",
        message: "",
        type: "textline",
        key: 3,
        Category: "ContractorSignupSMS",
        required: true
    },
    {
        Order: 1,
        options: [],
        VariableName: "SMSFunction2",
        VariableInput: "",
        Editable: false,
        TableColumn: true,
        extra: "",
        message: "",
        type: "select",
        key: 1001,
        Category: "ContractorSignupSMS",
        Searchable: true,
        required: false,
        label: "2nd SMS Function",
        DataSourse: "options",
        defaultValue: ["emergency"],
        options: [
            { key: "todo", label: "Add to-do list", value: "todo" },
            { key: "schedule", label: "Schedule service", value: "schedule" },
            { key: "emergency", label: "Emergency/Urgent Service", value: "emergency" },
            { key: "quote", label: "Request for Quote/Proposal", value: "quote" },
            { key: "dedicated", label: "Dedicated SMS", value: "dedicated" }
        ]
    },
    
    {
        Order: 1,
        options: [],
        label: "Instructions",
        VariableName: "Instructions",
        VariableInput: "",
        Editable: true,
        TableColumn: false,
        message: "",
        type: "textbox",
        key: 102221,
        Category: "ContractorSignupSMS",
        required: false,
    },

    //DevEdit
    {
        Order: 3,
        options: [],
        label: "SubDomain",
        VariableName: "SubDomain",
        extra: "field1",
        Editable: false,
        TableColumn: false,
        extra: "",
        VariableInput: "",
        message: "",
        type: "textline",
        key: 3,
        Category: "DevEdit",
        required: true
    },
    {
        Order: 3,
        options: [],
        label: "S3 Bucket",
        VariableName: "Bucket",
        extra: "field2",
        Editable: false,
        TableColumn: false,
        extra: "",
        VariableInput: "",
        message: "",
        type: "textline",
        key: 3,
        Category: "DevEdit",
        required: true
    },
    {
        Order: 3,
        options: [],
        label: "Subscription Fee",
        VariableName: "fee",
        extra: "",
        Editable: false,
        TableColumn: false,
        extra: "",
        VariableInput: "",
        message: "",
        type: "textline",
        key: 3,
        Category: "DevEdit",
        required: true
    },
    {
        Order: 2,
        options: [],
        label: "Status",
        VariableName: "status",
        defaultValue: true,
        Category: "DevEdit",
        VariableInput: true,
        message: "",
        type: "switch",
        key: "jvUSKJYssddsef",
        required: false,
        Searchable: true,
        Editable: true,
        TableColumn: false,
        extra: "",
    },

    //Company
    {
        Order: 1,
        options: [],
        label: "Company Name",
        VariableName: "CompanyName",
        VariableInput: "",
        Editable: false,
        TableColumn: true,
        extra: "field1",
        message: "",
        type: "textline",
        key: 100,
        Category: "Company",
        required: true,
        Searchable: true,
    },
    {
        Order: 1,
        options: [],
        label: "Business Type",
        VariableName: "BusinessType",
        VariableInput: "",
        Editable: true,
        TableColumn: false,
        extra: "field2",
        message: "",
        type: "textline",
        key: 101,
        Category: "Company",
        required: false,
    },
    
    {
        Order: 3,
        options: [],
        label: "Main Phone",
        VariableName: "phone",
        Searchable: true,
        Editable: true,
        TableColumn: false,
        extra: "",
        VariableInput: "",
        message: "",
        type: "phone",
        key: 3,
        Category: "Company",
        ListInfo: true,
        required: false
    },

    //Branch
    {
        Order: 1,
        options: [],
        label: "Branch Name",
        VariableName: "CompanyName",
        VariableInput: "",
        Editable: false,
        TableColumn: true,
        extra: "field1",
        message: "",
        type: "textline",
        key: 100,
        Category: "Branch",
        required: true,
        Searchable: true,
    },
    {
        Order: 3,
        options: [],
        label: "Branch Service Phone",
        VariableName: "phone",
        Searchable: true,
        Editable: true,
        TableColumn: false,
        extra: "",
        VariableInput: "",
        message: "",
        type: "phone",
        key: 3,
        Category: "Branch",
        required: false
    },

    {
        Order: 3,
        options: [],
        label: "Branch General Email",
        VariableName: "email",
        extra: "field2",
        Editable: false,
        TableColumn: false,
        extra: "",
        VariableInput: "",
        message: "",
        type: "email",
        key: 3,
        Category: "Branch",
        required: false
    },

    //Contractor signup Company
    {
        Order: 1,
        options: [],
        label: "Company Name",
        VariableName: "CompanyName",
        VariableInput: "",
        Editable: false,
        TableColumn: true,
        extra: "field1",
        message: "",
        type: "textline",
        key: 100,
        Category: "ContractorSignupCompany",
        required: true,
        Searchable: true,
    },
    {
        Order: 1,
        options: [],
        label: "Website",
        VariableName: "website",
        VariableInput: "",
        Editable: false,
        TableColumn: true,
        extra: "field2",
        message: "",
        type: "textline",
        key: 100,
        Category: "ContractorSignupCompany",
        required: true,
        Searchable: true,
    },
    {
        Order: 1,
        options: [],
        label: "Industry",
        VariableName: "Industry",
        VariableInput: "",
        Editable: false,
        TableColumn: true,
        extra: "field2",
        message: "",
        type: "textline",
        key: 100,
        Category: "ContractorSignupCompany",
        required: false,
        Searchable: true,
    },
    {
        Order: 1,
        options: [],
        VariableName: "BusinessFunction",
        VariableInput: "",
        Editable: false,
        TableColumn: true,
        extra: "",
        message: "",
        type: "radio",
        key: 1001,
        Category: "ContractorSignupCompany",
        Searchable: true,
        required: true,
        label: "Business Function",
        DataSourse: "options",
        defaultValue: ["contractor"],
        options: [
            { key: "facility", label: "Facility Operations", value: "facility" },
            { key: "contractor", label: "Contractor", value: "contractor" },
            { key: "IT", label: "IT Department", value: "IT" },
            { key: "other", label: "Other", value: "other" },
        ]
    },
    {
        Order: 1,
        options: [],
        label: "Short Business Description",
        VariableName: "BusinessDescription",
        VariableInput: "",
        Editable: true,
        TableColumn: false,
        extra: "",
        message: "",
        type: "textbox",
        key: 101,
        Category: "ContractorSignupCompany",
        required: false,
    },


    //Urgency

    {
        Order: 1,
        options: [],
        label: "Service Actions",
        VariableName: "Urgency",
        VariableInput: "",
        Editable: false,
        TableColumn: true,
        extra: "field1",
        message: "",
        type: "textline",
        key: 10122,
        Category: "Urgency",
        required: true,
        Searchable: true,
    },
    {
        Order: 1,
        options: [],
        VariableName: "UrgencyType",
        VariableInput: "",
        Editable: false,
        TableColumn: true,
        extra: "Urgency",
        message: "",
        type: "select",
        key: 1001,
        Category: "Urgency",
        Searchable: true,
        required: true,
        label: "Service Action Type",
        DataSourse: "options",
        defaultValue: ["todo"],
        options: [
            { key: "todo", label: "Add to-do list", value: "todo" },
            { key: "schedule", label: "Schedule service", value: "schedule" },
            { key: "emergency", label: "Emergency/Urgent Service", value: "emergency" },
            { key: "quote", label: "Request for Quote/Proposal", value: "quote" }
        ]
    },
    {
        Order: 1,
        options: [],
        label: "Notes",
        VariableName: "notes",
        VariableInput: "",
        Editable: true,
        TableColumn: false,
        extra: "",
        message: "",
        type: "textline",
        key: 102221,
        Category: "Urgency",
        required: false,
    },

    //Trade

    {
        Order: 1,
        options: [],
        label: "Specialty Service/Trade",
        VariableName: "Specilty",
        VariableInput: "",
        Editable: false,
        TableColumn: true,
        extra: "field1",
        message: "",
        type: "textline",
        key: 10122,
        Category: "Trade",
        required: true,
    },
    {
        Order: 1,
        options: [],
        label: "Description",
        VariableName: "Description",
        VariableInput: "",
        Editable: true,
        TableColumn: false,
        extra: "field2",
        message: "",
        type: "textbox",
        key: 102221,
        Category: "Trade",
        required: false,
    },

    //Area

    {
        Order: 1,
        options: [],
        label: "Area Territory Name",
        VariableName: "AreaName",
        VariableInput: "",
        Editable: false,
        TableColumn: true,
        extra: "field1",
        message: "",
        type: "textline",
        key: 10122,
        Category: "Area",
        required: true,
        Searchable: true,
    },
    {
        Order: 1,
        options: [],
        label: "Description",
        VariableName: "Description",
        VariableInput: "",
        Editable: true,
        TableColumn: false,
        extra: "field2",
        message: "",
        type: "textbox",
        key: 102221,
        Category: "Area",
        required: false,
    },

    //Area Select



    //Location  TimeZone
    {
        Order: 1,
        options: [],
        label: "Location/Building Name",
        VariableName: "Location",
        VariableInput: "",
        Editable: false,
        TableColumn: true,
        extra: "field1",
        message: "",
        type: "textline",
        key: 200,
        Category: "Location",
        ListInfo: true,
        required: true,
        Searchable: true,

    },

    {
        Order: 1,
        options: [],
        label: "Room",
        VariableName: "Room",
        extra: "",
        VariableInput: "",
        Editable: true,
        TableColumn: false,
        message: "",
        type: "textline",
        key: 202,
        Category: "Location",
        ListInfo: true,
        required: false,
        Searchable: true,
    },
    {
        Order: 1,
        options: [],
        label: "Address",
        VariableName: "Address",
        VariableInput: "",
        Editable: false,
        TableColumn: true,
        extra: "field2",
        message: "",
        type: "GoogleAddressAutoComplete",
        key: 200,
        Category: "Location",
        ListInfo: true,
        required: true,
        Searchable: true,
    },

    {
        Order: 1,
        options: [],
        label: "Time Zone",
        VariableName: "TimeZone",
        VariableInput: "",
        Editable: false,
        TableColumn: true,
        extra: "",
        message: "",
        type: "TimeZone",
        key: 200,
        Category: "Location",
        required: true,
        Searchable: true,

    },


    //{
    //    Order: 1,
    //    options: [],
    //    VariableName: "Area",
    //    VariableInput: "",
    //    Editable: false,
    //    TableColumn: true,
    //    extra: "",
    //    message: "",
    //    type: "select",
    //    key: 1001,
    //    Category: "Location",
    //    Searchable: true,
    //    required: true,
    //    label: "Area / Territory",
    //    DataSourse: "Area",
    //    defaultValue: [""],
    //    options: []
    //},

    //Address
    {
        Order: 1,
        options: [],
        label: "Address",
        VariableName: "Address",
        VariableInput: "",
        Editable: false,
        TableColumn: true,
        extra: "field2",
        message: "",
        type: "GoogleAddressAutoComplete",
        key: 200,
        Category: "Address",
        required: true,
        Searchable: true,
    },

    //Contractor Signup Location
    {
        Order: 1,
        options: [],
        label: "Main Location Address",
        VariableName: "Address",
        VariableInput: "",
        Editable: false,
        TableColumn: true,
        extra: "field1",
        message: "",
        type: "GoogleAddressAutoComplete",
        key: 200,
        Category: "ContractorSignupLocation",
        ListInfo: true,
        required: true,
        Searchable: true,
    },
    {
        Order: 1,
        options: [],
        label: "Time Zone",
        VariableName: "TimeZone",
        VariableInput: "",
        Editable: false,
        TableColumn: true,
        extra: "",
        message: "",
        type: "TimeZone",
        key: 200,
        Category: "ContractorSignupLocation",
        required: true,
        Searchable: true,
    },
    {
        Order: 1,
        options: [],
        label: "Number of Branch Offices",
        VariableName: "Branch",
        VariableInput: "",
        extra: "",
        Editable: true,
        TableColumn: false,
        message: "",
        type: "textline",
        key: 201,
        Category: "ContractorSignupLocation",
        required: false,
        Searchable: true,
    },

    //Building
    {
        Order: 1,
        options: [],
        label: "Building",
        VariableName: "Building",
        VariableInput: "",
        extra: "field1",
        Editable: true,
        TableColumn: false,
        message: "",
        type: "textline",
        key: 201,
        Category: "Building",
        required: false,
        Searchable: true,
    },

    // Room numbber
    {
        Order: 1,
        options: [],
        label: "Room",
        VariableName: "Room",
        extra: "field1",
        VariableInput: "",
        Editable: true,
        TableColumn: false,
        message: "",
        type: "textline",
        key: 202,
        Category: "Room",
        required: false,
        Searchable: true,
    },

    // Equipment
    {
        Order: 1,
        options: [],
        label: "Name/Tag",
        VariableName: "tag",
        VariableInput: "",
        Editable: false,
        TableColumn: true,
        extra: "field1",
        message: "",
        type: "textline",
        key: 300,
        Category: "Equipment",
        required: true,
        ListInfo: true,
        Searchable: true,
    },
    {
        Order: 1,
        options: [],
        label: "Model",
        VariableName: "Model",
        VariableInput: "",
        Editable: true,
        TableColumn: false,
        extra: "field2",
        message: "",
        type: "textline",
        key: 301,
        Category: "Equipment",
        ListInfo: true,
        required: false,
        Searchable: true,
    },
    {
        Order: 1,
        options: [],
        label: "Manufacturer",
        VariableName: "Manufacturer",
        VariableInput: "",
        Editable: true,
        TableColumn: false,
        extra: "",
        message: "",
        type: "textline",
        key: 302,
        Category: "Equipment",
        ListInfo: true,
        required: false,
        Searchable: true,
    },
    {
        Order: 1,
        options: [],
        label: "Serial",
        VariableName: "Serial",
        VariableInput: "",
        Editable: true,
        TableColumn: false,
        extra: "",
        message: "",
        type: "textline",
        key: 303,
        Category: "Equipment",
        ListInfo: true,
        required: false,
        Searchable: true,
    },
    {
        Order: 1,
        options: [],
        label: "Notes",
        VariableName: "Notes",
        VariableInput: "",
        Editable: true,
        TableColumn: false,
        extra: "",
        message: "",
        type: "textbox",
        key: 401,
        Category: "Equipment",
        required: false,
    },

    // Teams
    {
        Order: 1,
        options: [],
        label: "Team Name",
        VariableName: "name",
        VariableInput: "",
        Editable: false,
        TableColumn: true,
        extra: "field1",
        message: "",
        type: "textline",
        key: 400,
        Category: "Teams",
        required: true,
        Searchable: true,
    },
    {
        Order: 1,
        options: [],
        label: "Description",
        VariableName: "Description",
        VariableInput: "",
        Editable: true,
        TableColumn: false,
        extra: "field2",
        message: "",
        type: "textbox",
        key: 401,
        Category: "Teams",
        required: false,
    },

    //upload
    {
        Order: 1,
        options: [],
        label: "Upload",
        VariableName: "upload",
        VariableInput: "",
        Editable: false,
        TableColumn: true,
        extra: "",
        message: "",
        type: "upload",
        key: 1200,
        Category: "Upload",
        required: false,
    },

    //uploadDocument
    {
        Order: 1,
        options: [],
        label: "Document Title",
        VariableName: "name",
        VariableInput: "",
        Editable: false,
        TableColumn: true,
        extra: "field1",
        message: "",
        type: "textline",
        key: 400,
        Category: "uploadDocument",
        required: true,
        Searchable: true,
    },
    {
        Order: 1,
        options: [],
        VariableName: "DocumentType",
        VariableInput: "",
        Editable: false,
        TableColumn: true,
        extra: "field2",
        message: "",
        type: "select",
        key: 1001,
        Category: "uploadDocument",
        Searchable: true,
        required: true,
        label: "Document Type",
        DataSourse: "options",
        defaultValue: "All",
        options: GetDocumentType(),
        //    [
        //    { key: "report", label: "Report", value: "report" },
        //    { key: "drawings", label: "Drawings", value: "drawings" },
        //    { key: "manuals", label: "Techanical Manuals", value: "manuals" },
        //    { key: "quote", label: "Quote/Proposal", value: "quote" }
        //]
    },
    
    {
        Order: 1,
        options: [],
        label: "Description (optional)",
        VariableName: "Description",
        VariableInput: "",
        Editable: true,
        TableColumn: false,
        extra: "",
        message: "",
        type: "textbox",
        key: 401,
        Category: "uploadDocument",
        required: false,
    },
    {
        Order: 1,
        options: [],
        label: "Upload",
        VariableName: "upload",
        VariableInput: "",
        Editable: false,
        TableColumn: true,
        extra: "",
        message: "",
        type: "upload",
        key: 1200,
        Category: "uploadDocument",
        required: true,
    },


    //New Service
    {
        Order: 1,
        options: [],
        label: "Title",
        VariableName: "title",
        VariableInput: "",
        Editable: false,
        TableColumn: true,
        extra: "field1",
        message: "",
        type: "textline",
        key: 1000,
        Category: "NewService",
        required: true,
        Searchable: true,
    },
    {
        Order: 1,
        options: [],
        VariableName: "Urgency",
        VariableInput: "",
        Editable: false,
        TableColumn: true,
        extra: "field2",
        message: "",
        type: "select",
        key: 1001,
        Category: "NewService",
        Searchable: true,
        required: true,
        label: "Service Urgency",
        DataSourse: "UrgencySelected",
        defaultValue: ["todo"],
        options: [
            { key: "todo", label: "Add to-do list", value: "todo" },
            { key: "schedule", label: "Schedule service", value: "schedule" },
            { key: "emergency", label: "Emergency/Urgent Service", value: "emergency" },
            { key: "quote", label: "Request for Quote/Proposal", value: "quote" }
        ]
    },
    {
        Order: 1,
        options: [],
        VariableName: "Trade",
        VariableInput: "",
        Editable: false,
        TableColumn: true,
        extra: "trade",
        message: "",
        type: "select",
        key: 1002,
        Category: "NewService",
        required: true,
        Searchable: true,
        label: "Service Trade",
        DataSourse: "TradeSelected",
        defaultValue: ["todo"],
        options: [
            { key: "todo", label: "Add to-do list", value: "" },
            { key: "schedule", label: "Schedule service", value: "" },
            { key: "emergency", label: "Emergency/Urgent Service", value: "emergency" },
            { key: "quote", label: "Request for Quote/Proposal", value: "quote" }
        ]
    },
    {
        Order: 1,
        options: [],
        label: "Description",
        VariableName: "Description",
        VariableInput: "",
        Editable: true,
        TableColumn: false,
        extra: "post",
        message: "",
        type: "Quill",
        key: 1003,
        Category: "NewService",
        required: false,
    },

    {

        Order: 10,
        options: [],
        label: "Date Request",
        VariableName: "date",
        VariableInput: "",
        defaultValue: true,
        message: "",
        type: "datepicker",
        key: "jvUSKJYwwsessf",
        required: false,
        message: "",
        Searchable: true,
        key: 1004,
        Category: "NewService",

    }, {

        Order: 10,
        options: [],
        label: "Time Request",
        VariableName: "time",
        defaultValue: true,
        Searchable: true,
        VariableInput: "",
        message: "",
        type: "timepicker",
        key: "jvUSKJYwwsessf",
        required: false,
        message: "",
        key: 1005,
        Category: "NewService",
    },

    //Payment
    {
        Order: 20,
        VariableName: "PaymentMethod",
        VariableInput: "",
        message: "",
        type: "radio",
        extra: "field1",
        key: 1100,
        Category: "Payment",
        Searchable: true,
        required: false,
        label: "Payment Method",
        DataSourse: "options",
        defaultValue: ["NewPO"],
        options: [
            { key: "existingservice", label: "Part of Existing Service", value: "existingservice" },
            { key: "NewPO", label: "New PO", value: "NewPO" },
            { key: "warranty", label: "Warranty", value: "warranty" },
            { key: "blanketPO", label: "Use Existing Blanket PO", value: "blanketPO" }
        ]
    },

    //Invite
    {
        Order: 20,
        VariableName: "Invite",
        VariableInput: "",
        message: "",
        type: "radio",
        extra: "Invite",
        key: 11022,
        Category: "Invite",
        Searchable: true,
        required: false,
        label: "Invite Person",
        DataSourse: "options",
        defaultValue: ["email"],
        options: [
            { key: "email", label: "Send Email to Invite Person", value: "email" },
            { key: "SMS", label: "Send text/SMS message to Invite Person", value: "SMS" },
            { key: "no", label: "No Invitation", value: "no" },
        ]
    },

    {
        Order: 1,
        options: [],
        label: "Invitation Message",
        VariableName: "message",
        VariableInput: "",
        Editable: true,
        TableColumn: false,
        extra: "Invite",
        message: "",
        type: "textbox",
        key: 401,
        Category: "Invite",
        required: false,
    },

];

const GetFromDB = async (FormId) => {
    const ContractorData = useSelector(state => state.ContractorData);
    let ContractorEsc = await PrimaryKeyDataGet(ContractorData.ContractorId, FormId);
    return ContractorEsc;
}

export const FormArray = (Category, formidd) => {

    try {

        //formgroup contains forms that will be presented together
        let FormGroupSelect = FormGroups(Category);
        let FormDataRaw = [];
        let NewData = [];
       // let FormId = FormId1;
        let FormInfo = {};
        console.log("FormGroupSelect Cat", Category, FormGroupSelect)
        FormGroupSelect.map((each, index) => {

            if (each.FormType === "AutoComplate") {
               // FormDataRaw[formidd] = [];
              //  FormDataRaw = [];

                let totall1 = {
                 //   key: `${each.Category}::${formidd}::${index}`,
                 //   Data: {
                        FormData: [],
                        FormType: each.FormType,
                        key: `Form::${each.Category}::${formidd}::${index}`,
                        SearchWords: "x",
                        FormName: each.FormName,
                        Category: each.Category,
                        field1: each.FormName,
                        ValueAdded: false
                //    }
                }

                console.log("FormDataCCat2.totall1", totall1);

                NewData.push(totall1);

            } else {
                
                //FormDataRaw[formidd] = FormSchema.filter(
                //    x => x.Category === each.Category
                //        && x.key !== formidd
                //);

                FormSchema.map((x, indd) => {
                    if (x.Category === each.Category) {
                        FormDataRaw.push({
                            ...x,
                            key: formidd + indd
                        })
                    }
                });

                let totall = {
                 //   key: `${each.Category}::${formidd}::${index}`,
                 //   Data: {
                        FormData: FormDataRaw,
                        FormType: each.FormType,
                        key: `Form::${each.Category}::${formidd}::${index}`,
                        SearchWords: "x",
                        FormName: each.FormName,
                        Category: each.Category,
                        field1: each.FormName,
                        ValueAdded: false
                 //   }
                }

                console.log("FormDataCCat2.totall2", totall);

                NewData.push(totall);

            }      

           FormDataRaw = [];

        })

        console.log("FormDataCCat2.total", NewData);

        return NewData;

    } catch (err) { console.log("FormArray err", err) }
};


export const FormGroups = (Catagory) => {

    let FormGroup = [];
    switch (Catagory) {
        case "NewService":
            FormGroup = [
                {
                    FormType: "NewService",
                    FormName: "New Service Request",
                    Category: "NewService",
                    propss: ""
                },
                //{
                //  FormType: "AutoComplate",
                //  FormName: "Equipment",
                //  Category: "Equipment",
                //  propss: "Equipment"
                //},
                //{
                //  FormType: "Payment",
                //  FormName: "Payment Type",
                //  Category: "Payment",
                //  propss: "Payment"
                //},
                //{
                //  FormType: "Upload",
                //  FormName: "Upload Picture/Documents",
                //  Category: "Upload",
                //  propss: "Upload"
                //},
            ]
            break;

        case "Person":
            FormGroup = [
                {
                    FormType: "Person",
                    FormName: "Person Infomation",
                    Category: "Person",
                    propss: ""
                },
                //{
                //    FormType: "AutoComplate",
                //    FormName: "Location",
                //    Category: "Location",
                //    propss: "Location"
                //},
                //{
                //    FormType: "AutoComplate",
                //    FormName: "Teams",
                //    Category: "Teams",
                //    propss: "Teams"
                //},
                {
                    FormType: "Invite",
                    FormName: "Invite Person to setup Account",
                    Category: "Invite",
                    propss: ""
                },
            ]
            break;

        case "Company":
            FormGroup = [
                {
                    FormType: "Company",
                    FormName: "Company Infomation",
                    Category: "Company",
                    propss: ""
                },
                //{
                //    FormType: "AutoComplate",
                //    FormName: "Location Address",
                //    Category: "Location",
                //    propss: "Location"
                //},
            ]
            break;

        case "Branch":
            FormGroup = [
                {
                    FormType: "Branch",
                    FormName: "Branch Information",
                    Category: "Branch",
                    propss: ""
                },
                //{
                //    FormType: "Location",
                //    FormName: "Location Address",
                //    Category: "Location",
                //    propss: "Location"
                //},
            ]
            break;

        case "Contractor":
            FormGroup = [
                {
                    FormType: "Company",
                    FormName: "Company Infomation",
                    Category: "Company",
                    propss: ""
                },
                {
                    FormType: "AutoComplate",
                    FormName: "Location",
                    Category: "Location",
                    propss: "Location"
                },
                //{
                //  FormType: "AutoComplate",
                //  FormName: "Address",
                //  Category: "Address",
                //  propss: ""
                //},
                //{
                //  FormType: "AutoComplate",
                //  FormName: "Building",
                //  Category: "Building",
                //  propss: "Building"
                //},
                //{
                //  FormType: "AutoComplate",
                //  FormName: "Room",
                //  Category: "Room",
                //  propss: "Room"
                //},
                //{
                //  FormType: "AreaSelect",
                //  FormName: "Area / Territory",
                //  Category: "AreaSelect",
                //  propss: ""
                //},
            ]
            break;

        case "Equipment":
            FormGroup = [
                {
                    FormType: "Equipment",
                    FormName: "Equipment Infomation",
                    Category: "Equipment",
                    propss: "Equipment"
                },
                {
                    FormType: "AutoComplate",
                    FormName: "Location",
                    Category: "Location",
                    propss: "Location"
                },
                //{
                //  FormType: "AutoComplate",
                //  FormName: "Building",
                //  Category: "Building",
                //  propss: "Building"
                //},
                //{
                //  FormType: "AutoComplate",
                //  FormName: "Room",
                //  Category: "Room",
                //  propss: "Room"
                //},
            ]
            break;

        case "Urgency":
            FormGroup = [
                {
                    FormType: "Urgency",
                    FormName: "Service Urgency",
                    Category: "Urgency",
                    propss: ""
                },
            ]
            break;

        case "DevEdit":
            FormGroup = [
                {
                    FormType: "DevEdit",
                    FormName: "Developer Settings",
                    Category: "DevEdit",
                    propss: ""
                },
            ]
            break;


        case "RequestNewSMS":
            FormGroup = [
                {
                    FormType: "RequestNewSMS",
                    FormName: "Request New SMS Number",
                    Category: "RequestNewSMS",
                    propss: ""
                },
            ]
            break;

        case "AddSMS":
            FormGroup = [
                {
                    FormType: "AddSMS",
                    FormName: "Add SMS",
                    Category: "AddSMS",
                    propss: ""
                },
            ]
            break;

        case "EmailTrigger":
            FormGroup = [
                {
                    FormType: "EmailTrigger",
                    FormName: "Email Trigger",
                    Category: "EmailTrigger",
                    propss: ""
                },
            ]
            break;

        case "Trade":
            FormGroup = [
                {
                    FormType: "Trade",
                    FormName: "Service Trade",
                    Category: "Trade",
                    propss: ""
                },
            ]
            break;

        case "Area":
            FormGroup = [
                {
                    FormType: "Area",
                    FormName: "Service Area",
                    Category: "Area",
                    propss: ""
                },
            ]
            break;

        case "Address":
            FormGroup = [
                {
                    FormType: "Address",
                    FormName: "Address",
                    Category: "Address",
                    propss: ""
                },
            ]
            break;
            
        case "uploadDocument":
            FormGroup = [
                {
                    FormType: "uploadDocument",
                    FormName: "Add Document",
                    Category: "uploadDocument",
                    propss: ""
                },
            ]
            break;

        case "Location":
            FormGroup = [
                {
                    FormType: "Location",
                    FormName: "Location/Building Name",
                    Category: "Location",
                    propss: ""
                },
                //{
                //  FormType: "Building",
                //  FormName: "Building Name/Number",
                //  Category: "Building",
                //  propss: ""
                //},
                //{
                //  FormType: "Room",
                //  FormName: "Room No.",
                //  Category: "Location",
                //  propss: ""
                //},
                //{
                //  FormType: "Address",
                //  FormName: "Address Lookup",
                //  Category: "Location",
                //  propss: ""
                //},

                //{
                //  FormType: "AreaSelect",
                //  FormName: "Area / Territory",
                //  Category: "AreaSelect",
                //  propss: ""
                //},
            ]
            break;
        case "Building":
            FormGroup = [
                {
                    FormType: "Building",
                    FormName: "Building",
                    Category: "Building",
                    propss: ""
                },
            ]
            break;
        case "Room":
            FormGroup = [
                {
                    FormType: "Room",
                    FormName: "Room",
                    Category: "Room",
                    propss: ""
                },
            ]
            break;
        case "Teams":
            FormGroup = [
                {
                    FormType: "Teams",
                    FormName: "Teams",
                    Category: "Teams",
                    propss: ""
                },
            ]
            break;

        default:
            FormGroup = [
                {
                    FormType: "Location",
                    FormName: "Location",
                    Category: "Location",
                    propss: ""
                },
            ]

    }
    return FormGroup
}


const FormJSON = [
    {
        Order: 1,
        options: [],
        label: "Name",
        VariableName: "name",
        VariableInput: "",
        message: "",
        type: "textline",
        key: "jvUSKJYef",
        required: true,
    },
    {
        Order: 100,
        options: [],
        label: "Password",
        VariableName: "password",
        VariableInput: "",
        message: "",
        type: "password",
        key: "jvUSKJYef",
        required: true,
    },
    {
        Order: 1,
        options: [],
        label: "Email",
        VariableName: "email",
        VariableInput: "",
        message: "",
        type: "email",
        key: "jvUSKJYef",
        required: true,
    },
    {
        Order: 30,
        options: [],
        label: "Address",
        VariableName: "address",
        VariableInput: "",
        defaultValue: "",
        message: "",
        type: "GoogleAddressAutoComplete",
        key: "jvUSwlsksKJYef",
        required: true,
    },
    {

        Order: 2,
        options: [],
        label: "switch",
        VariableName: "name",
        defaultValue: true,
        VariableInput: "",
        message: "",
        type: "switch",
        key: "jvUSKJYef",
        required: true

    },
    {

        Order: 9,
        options: [],
        label: "check box",
        VariableName: "checkbox",
        defaultValue: true,
        VariableInput: "",
        message: "",
        type: "checkbox",
        key: "jvUSKJYessf",
        required: true

    },
    {

        Order: 10,
        options: [],
        label: "date picker",
        VariableName: "date picker",
        defaultValue: true,
        VariableInput: "",
        message: "",
        type: "datepicker",
        key: "jvUSKJYwwsessf",
        required: true,
        message: "what is this"

    }, {

        Order: 10,
        options: [],
        label: "time picker",
        VariableName: "time picker",
        defaultValue: true,
        VariableInput: "",
        message: "",
        type: "timepicker",
        key: "jvUSKJYwwsessf",
        required: true,
        message: "what is this"

    },
    {
        Order: 3,
        options: [],
        label: "Mobile Phone",
        VariableName: "Mobile",
        VariableInput: "+15627601614",
        message: "tom be top",
        type: "phone",
        key: "iho7AcjlhjgfaW",
        required: true
    },

    {
        Order: 7,
        VariableName: "payment",
        VariableInput: "",
        message: "",
        type: "select",
        key: "iho7AcjaW33",
        required: false,
        label: "Payment Method",
        DataSourse: "options",
        defaultValue: ["NewPO"],
        options: [
            { key: "existingservice", label: "Part of Existing Service", value: "existingservice" },
            { key: "NewPO", label: "New PO", value: "NewPO" },
            { key: "warranty", label: "Warranty", value: "warranty" },
            { key: "blanketPO", label: "Use Existing Blanket PO", value: "blanketPO" }
        ]
    },
    {
        Order: 20,
        VariableName: "Radio",
        VariableInput: "",
        message: "",
        type: "radio",
        key: "iho7AcjeesaWfds33",
        required: false,
        label: "Radio Method",
        DataSourse: "options",
        defaultValue: ["NewPO"],
        options: [
            { key: "existingservice", label: "Part of Existing Service", value: "existingservice" },
            { key: "NewPO", label: "New PO", value: "NewPO" },
            { key: "warranty", label: "Warranty", value: "warranty" },
            { key: "blanketPO", label: "Use Existing Blanket PO", value: "blanketPO" }
        ]
    }
];



